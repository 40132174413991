import { type ComponentProps, Suspense, useState } from 'react';
import { useEffectOnce } from 'react-use';

type LateSuspenseProps = ComponentProps<typeof Suspense>;

/**
 * A higher-order component that renders its children wrapped in a Suspense component,
 * but only after the first load has occurred. The use case, if the lazy components have
 * the useSuspenseQuery hook, it will take the global loading state and will not show the fallback
 *
 * @param children - The children components to be rendered.
 * @param props - Additional props to be passed to the Suspense component.
 * @returns The wrapped children components.
 */
export const LateSuspense = ({ children, ...props }: LateSuspenseProps) => {
	const [isWasFirstLoad, setIsWasFirstLoad] = useState(false);

	useEffectOnce(() => {
		setIsWasFirstLoad(true);
	});

	if (!isWasFirstLoad) {
		return children;
	}

	return <Suspense {...props}>{children}</Suspense>;
};
