/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type PendingGracePeriodQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PendingGracePeriodQuery = { me?: { sign_in_method?: Types.UsersignInMethod | null, pending_payment_leave?: { id: number, fee_amount: number } | null } | null };



export const PendingGracePeriodDocument = `
    query PendingGracePeriod {
  me(is_me: true) {
    sign_in_method
    pending_payment_leave {
      id
      fee_amount
    }
  }
}
    `;

export const usePendingGracePeriodQuery = <
      TData = PendingGracePeriodQuery,
      TError = unknown
    >(
      variables?: PendingGracePeriodQueryVariables,
      options?: Omit<UseQueryOptions<PendingGracePeriodQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<PendingGracePeriodQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<PendingGracePeriodQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['PendingGracePeriod'] : ['PendingGracePeriod', variables],
    queryFn: fetcher<PendingGracePeriodQuery, PendingGracePeriodQueryVariables>(PendingGracePeriodDocument, variables),
    ...options
  }
    )};

usePendingGracePeriodQuery.getKey = (variables?: PendingGracePeriodQueryVariables) => variables === undefined ? ['PendingGracePeriod'] : ['PendingGracePeriod', variables];

export const useSuspensePendingGracePeriodQuery = <
      TData = PendingGracePeriodQuery,
      TError = unknown
    >(
      variables?: PendingGracePeriodQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<PendingGracePeriodQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<PendingGracePeriodQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<PendingGracePeriodQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['PendingGracePeriodSuspense'] : ['PendingGracePeriodSuspense', variables],
    queryFn: fetcher<PendingGracePeriodQuery, PendingGracePeriodQueryVariables>(PendingGracePeriodDocument, variables),
    ...options
  }
    )};

useSuspensePendingGracePeriodQuery.getKey = (variables?: PendingGracePeriodQueryVariables) => variables === undefined ? ['PendingGracePeriodSuspense'] : ['PendingGracePeriodSuspense', variables];


usePendingGracePeriodQuery.fetcher = (variables?: PendingGracePeriodQueryVariables, options?: RequestInit['headers']) => fetcher<PendingGracePeriodQuery, PendingGracePeriodQueryVariables>(PendingGracePeriodDocument, variables, options);
