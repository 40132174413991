import { useIsFirstCreditAccountWithdrawal } from '@entities/credit-account-withdrawal';
import { useUserCreditAccount } from '@entities/user';
import { type FC, type ReactElement, lazy } from 'react';
import { useCreditLineBalanceState } from '../hooks';
import { CreditLineBalanceState } from '../types';

const CreditLineBalanceNoSigned = lazy(() =>
	import('./credit-line-no-signed').then((module) => ({
		default: module.CreditLineBalanceNoSigned,
	})),
);
const CreditLineBalanceSigned = lazy(() =>
	import('./CreditLineBalanceSigned').then((module) => ({
		default: module.CreditLineBalanceSigned,
	})),
);
const CreditLineBalanceLateInvoice = lazy(() =>
	import('./CreditLineBalanceLateInvoice').then((module) => ({
		default: module.CreditLineBalanceLateInvoice,
	})),
);
const CreditLineBalanceNoLimit = lazy(() =>
	import('./CreditLineBalanceNoLimit').then((module) => ({
		default: module.CreditLineBalanceNoLimit,
	})),
);

const renderCreditLineBalance: Record<
	Exclude<CreditLineBalanceState, CreditLineBalanceState.NO_SIGNED>,
	(data: {
		isFirstWithdrawal: boolean;
		creditLimit: number;
		unpaidPrincipal: number;
		canRequestLimitIncrease: boolean;
		shouldBeModified: boolean;
	}) => ReactElement
> = {
	[CreditLineBalanceState.SIGNED]: (data) => (
		<CreditLineBalanceSigned {...data} />
	),
	[CreditLineBalanceState.LATE_INVOICE]: ({
		creditLimit,
		unpaidPrincipal,
		canRequestLimitIncrease,
		shouldBeModified,
	}) => (
		<CreditLineBalanceLateInvoice
			creditLimit={creditLimit}
			unpaidPrincipal={unpaidPrincipal}
			canRequestLimitIncrease={canRequestLimitIncrease}
			shouldBeModified={shouldBeModified}
		/>
	),
	[CreditLineBalanceState.NO_CREDIT_LEFT]: ({
		creditLimit,
		canRequestLimitIncrease,
		shouldBeModified,
	}) => (
		<CreditLineBalanceNoLimit
			creditLimit={creditLimit}
			canRequestLimitIncrease={canRequestLimitIncrease}
			shouldBeModified={shouldBeModified}
		/>
	),
};

type CreditLineBalanceProps = {
	isCreditLinePage?: boolean;
};

export const CreditLineBalance: FC<CreditLineBalanceProps> = ({
	isCreditLinePage = false,
}) => {
	const { data: creditAccount } = useUserCreditAccount();
	const { data: isFirstCreditAccountWithdrawal } =
		useIsFirstCreditAccountWithdrawal(creditAccount?.id);

	const creditLineBalanceState = useCreditLineBalanceState();

	if (!creditLineBalanceState) {
		return null;
	}

	if (
		!creditAccount ||
		creditLineBalanceState === CreditLineBalanceState.NO_SIGNED
	) {
		return <CreditLineBalanceNoSigned isCreditLinePage={isCreditLinePage} />;
	}

	return renderCreditLineBalance[creditLineBalanceState]({
		creditLimit: creditAccount.creditLimit,
		isFirstWithdrawal: !!isFirstCreditAccountWithdrawal,
		unpaidPrincipal: creditAccount.unpaidPrincipal,
		canRequestLimitIncrease: creditAccount.canRequestLimitIncrease,
		shouldBeModified: creditAccount.shouldBeModified,
	});
};
