import type { RouteName } from '@config/routes';
import CloseIcon from '@icons/close-sharp.svg?react';
import { Link } from '@tanstack/react-router';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './DualPanelLayout.module.css';

type DualPanelLayoutProps = PropsWithChildren<{
	left: ReactNode;
	right: ReactNode;
	fromPage: RouteName;
}>;

/**
 * DualPanelLayout component provides a responsive dual-panel layout with a left and a right panel.
 *
 * @param {RouteName} fromPage - The route name to navigate back to, typically used for the close button functionality.
 * @param {ReactNode} left - The content to be displayed in the left panel.
 * @param {ReactNode} right - The content to be displayed in the right panel.
 * @returns {JSX.Element} A JSX element representing the dual-panel layout.
 */
export const DualPanelLayout: FC<DualPanelLayoutProps> = ({
	fromPage,
	left,
	right,
}) => (
	<div className={styles.container}>
		<header className={styles.header}>
			<Link to={fromPage}>
				<CloseIcon />
			</Link>
		</header>
		<main className={styles.main}>
			{left ? <div className={styles.left}>{left}</div> : null}
			{right ? <div className={styles.right}>{right}</div> : null}
		</main>
	</div>
);
