import { creditAccountWithdrawalApi } from '../api';
export const useIsFirstCreditAccountWithdrawal = (
	creditAccountId: Nullish<number>,
) =>
	creditAccountWithdrawalApi.useCreditAccountWithdrawalsCountQuery(
		{
			creditAccountId: creditAccountId ?? -1,
		},
		{
			enabled: !!creditAccountId,
			select: (data) =>
				!!data?.credit_account_withdrawals?.data?.length &&
				data.credit_account_withdrawals.data.length === 0,
		},
	);
