/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type TrackRudderStackEventMutationVariables = Types.Exact<{
  event_name: Types.Scalars['String']['input'];
  properties: Types.Scalars['String']['input'];
}>;


export type TrackRudderStackEventMutation = { cdp_track?: boolean | null };



export const TrackRudderStackEventDocument = `
    mutation TrackRudderStackEvent($event_name: String!, $properties: String!) {
  cdp_track(event_name: $event_name, properties: $properties)
}
    `;

export const useTrackRudderStackEventMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TrackRudderStackEventMutation, TError, TrackRudderStackEventMutationVariables, TContext>) => {
    
    return useMutation<TrackRudderStackEventMutation, TError, TrackRudderStackEventMutationVariables, TContext>(
      {
    mutationKey: ['TrackRudderStackEvent'],
    mutationFn: (variables?: TrackRudderStackEventMutationVariables) => fetcher<TrackRudderStackEventMutation, TrackRudderStackEventMutationVariables>(TrackRudderStackEventDocument, variables)(),
    ...options
  }
    )};


useTrackRudderStackEventMutation.fetcher = (variables: TrackRudderStackEventMutationVariables, options?: RequestInit['headers']) => fetcher<TrackRudderStackEventMutation, TrackRudderStackEventMutationVariables>(TrackRudderStackEventDocument, variables, options);
