export enum CreditAccountWithdrawalEligibilityState {
	NEGATIVE = -1,
	SCORING = 0,
	SUCCESS = 1,
}

export enum SimpleEligibilityStatus {
	CONFIRM_BANK = 'CONFIRM_BANK',
	CONFIRM_BANK_ACCOUNT_SCORING = 'CONFIRM_BANK_ACCOUNTSCORING',
	MONTHLY_PAYMENT_TOO_HIGH = 'MONTHLY_PAYMENT_TOO_HIGH',
	NEGATIVE = 'NEGATIVE',
	NEGATIVE_FULL = 'NEGATIVE_FULL',
	NEGATIVE_FORWARD = 'NEGATIVE_FORWARD',
	NO_USER = 'NO_USER',
	NOT_ENOUGH_CREDIT_FOR_THIS_WITHDRAWAL = 'NOT_ENOUGH_CREDIT_FOR_THIS_WITHDRAWAL',
	OUTSIDE_WORKING_HOURS = 'OUTSIDE_WORKING_HOURS',
	SOME_REQUIRED_INFO_IS_MISSING = 'SOME_REQUIRED_INFO_IS_MISSING',
	PENDING = 'PENDING',
	PENDING_CUSTOMER_CARE = 'PENDING_CUSTOMER_CARE',
	POSITIVE = 'POSITIVE',
	WAITING_SPOUSE_CONSENT = 'WAITING_SPOUSE_CONSENT',
	EMTA_CONSENT = 'EMTA_CONSENT',
	CHECK_INCOME = 'CHECK_INCOME',
	DSTI_DSCR_INSUFFICIENT = 'DSTI_DSCR_INSUFFICIENT',
	MANUAL_SCORING_NEEDED = 'MANUAL_SCORING_NEEDED',
}
