/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type EparakstsLoginChallengeMutationVariables = Types.Exact<{
  method: Types.EparakstsAuthorizationMethod;
  return_url: Types.Scalars['String']['input'];
}>;


export type EparakstsLoginChallengeMutation = { eparaksts_login_challenge?: { redirect_url: string } | null };



export const EparakstsLoginChallengeDocument = `
    mutation EparakstsLoginChallenge($method: EparakstsAuthorizationMethod!, $return_url: String!) {
  eparaksts_login_challenge(method: $method, return_url: $return_url) {
    redirect_url
  }
}
    `;

export const useEparakstsLoginChallengeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EparakstsLoginChallengeMutation, TError, EparakstsLoginChallengeMutationVariables, TContext>) => {
    
    return useMutation<EparakstsLoginChallengeMutation, TError, EparakstsLoginChallengeMutationVariables, TContext>(
      {
    mutationKey: ['EparakstsLoginChallenge'],
    mutationFn: (variables?: EparakstsLoginChallengeMutationVariables) => fetcher<EparakstsLoginChallengeMutation, EparakstsLoginChallengeMutationVariables>(EparakstsLoginChallengeDocument, variables)(),
    ...options
  }
    )};


useEparakstsLoginChallengeMutation.fetcher = (variables: EparakstsLoginChallengeMutationVariables, options?: RequestInit['headers']) => fetcher<EparakstsLoginChallengeMutation, EparakstsLoginChallengeMutationVariables>(EparakstsLoginChallengeDocument, variables, options);
