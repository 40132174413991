import ArrowsIcon from '@/shared/assets/icons/loader/arrows.svg?react';
import DiamondIcon from '@/shared/assets/icons/loader/diamond.svg?react';
import type { FC } from 'react';
import { cn } from '../utils/tailwind';

type LoadingSpinnerProps = {
	className?: string;
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className }) => (
	<div
		className={cn(
			'relative flex size-8 items-center justify-center',
			className,
		)}
	>
		<ArrowsIcon
			className={cn(
				'absolute top-0 left-0 size-full animate-loader-arrows-spin',
			)}
		/>
		<DiamondIcon className="animate-loader-diamond-spin" />
	</div>
);
