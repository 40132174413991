/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserQuery = { me?: { id: number, email?: string | null, phone?: string | null, newsletter_agreement: boolean, language_abbr: string, created_at: number, profile?: { first_name?: string | null, last_name?: string | null } | null } | null };

export type UserCreditAccountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserCreditAccountQuery = { me?: { disposable_income_without_ca?: number | null, credit_accounts?: Array<{ id: number, credit_limit: number, should_be_modified: boolean, status?: Types.CreditAccountStatus | null, unpaid_principal: number, can_request_limit_increase: boolean, signed_at?: string | null } | null> | null } | null };

export type UserPremiumSubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPremiumSubscriptionQuery = { me?: { premium_subscription_status: string, active_premium_subscription?: { id: number, fee_amount: number, end_at: string } | null } | null };

export type UserProfileInfoFragment = { political_exposure?: Types.PoliticalExposure | null, profile?: { address?: string | null, post_code?: string | null, city?: string | null, occupation_category?: Types.OccupationCategory | null, employment_date?: string | null, iban?: string | null, net_income_monthly?: number | null, monthly_living_expenses?: number | null, expenditure_monthly?: number | null, planning_new_debts?: number | null, future_reduced_earnings?: number | null, ultimate_beneficial_owner?: boolean | null, number_of_dependents?: number | null, overdue_debt?: number | null } | null };

export type UserProfileInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserProfileInfoQuery = { me?: { political_exposure?: Types.PoliticalExposure | null, profile?: { address?: string | null, post_code?: string | null, city?: string | null, occupation_category?: Types.OccupationCategory | null, employment_date?: string | null, iban?: string | null, net_income_monthly?: number | null, monthly_living_expenses?: number | null, expenditure_monthly?: number | null, planning_new_debts?: number | null, future_reduced_earnings?: number | null, ultimate_beneficial_owner?: boolean | null, number_of_dependents?: number | null, overdue_debt?: number | null } | null } | null };


export const UserProfileInfoFragmentDoc = `
    fragment UserProfileInfo on User {
  political_exposure
  profile {
    address
    post_code
    city
    occupation_category
    employment_date
    iban
    net_income_monthly
    monthly_living_expenses
    expenditure_monthly
    planning_new_debts
    future_reduced_earnings
    ultimate_beneficial_owner
    number_of_dependents
    overdue_debt
  }
}
    `;
export const UserDocument = `
    query User {
  me(is_me: true) {
    id
    email
    phone
    newsletter_agreement
    language_abbr
    created_at
    profile {
      first_name
      last_name
    }
  }
}
    `;

export const useUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      variables?: UserQueryVariables,
      options?: Omit<UseQueryOptions<UserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['User'] : ['User', variables],
    queryFn: fetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
    ...options
  }
    )};

useUserQuery.getKey = (variables?: UserQueryVariables) => variables === undefined ? ['User'] : ['User', variables];

export const useSuspenseUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      variables?: UserQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserSuspense'] : ['UserSuspense', variables],
    queryFn: fetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
    ...options
  }
    )};

useSuspenseUserQuery.getKey = (variables?: UserQueryVariables) => variables === undefined ? ['UserSuspense'] : ['UserSuspense', variables];


useUserQuery.fetcher = (variables?: UserQueryVariables, options?: RequestInit['headers']) => fetcher<UserQuery, UserQueryVariables>(UserDocument, variables, options);

export const UserCreditAccountDocument = `
    query UserCreditAccount {
  me(is_me: true) {
    disposable_income_without_ca
    credit_accounts {
      id
      credit_limit
      should_be_modified
      status
      unpaid_principal
      can_request_limit_increase
      signed_at
    }
  }
}
    `;

export const useUserCreditAccountQuery = <
      TData = UserCreditAccountQuery,
      TError = unknown
    >(
      variables?: UserCreditAccountQueryVariables,
      options?: Omit<UseQueryOptions<UserCreditAccountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserCreditAccountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserCreditAccountQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditAccount'] : ['UserCreditAccount', variables],
    queryFn: fetcher<UserCreditAccountQuery, UserCreditAccountQueryVariables>(UserCreditAccountDocument, variables),
    ...options
  }
    )};

useUserCreditAccountQuery.getKey = (variables?: UserCreditAccountQueryVariables) => variables === undefined ? ['UserCreditAccount'] : ['UserCreditAccount', variables];

export const useSuspenseUserCreditAccountQuery = <
      TData = UserCreditAccountQuery,
      TError = unknown
    >(
      variables?: UserCreditAccountQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserCreditAccountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserCreditAccountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserCreditAccountQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditAccountSuspense'] : ['UserCreditAccountSuspense', variables],
    queryFn: fetcher<UserCreditAccountQuery, UserCreditAccountQueryVariables>(UserCreditAccountDocument, variables),
    ...options
  }
    )};

useSuspenseUserCreditAccountQuery.getKey = (variables?: UserCreditAccountQueryVariables) => variables === undefined ? ['UserCreditAccountSuspense'] : ['UserCreditAccountSuspense', variables];


useUserCreditAccountQuery.fetcher = (variables?: UserCreditAccountQueryVariables, options?: RequestInit['headers']) => fetcher<UserCreditAccountQuery, UserCreditAccountQueryVariables>(UserCreditAccountDocument, variables, options);

export const UserPremiumSubscriptionDocument = `
    query UserPremiumSubscription {
  me(is_me: true) {
    premium_subscription_status
    active_premium_subscription {
      id
      fee_amount
      end_at
    }
  }
}
    `;

export const useUserPremiumSubscriptionQuery = <
      TData = UserPremiumSubscriptionQuery,
      TError = unknown
    >(
      variables?: UserPremiumSubscriptionQueryVariables,
      options?: Omit<UseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserPremiumSubscriptionQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserPremiumSubscription'] : ['UserPremiumSubscription', variables],
    queryFn: fetcher<UserPremiumSubscriptionQuery, UserPremiumSubscriptionQueryVariables>(UserPremiumSubscriptionDocument, variables),
    ...options
  }
    )};

useUserPremiumSubscriptionQuery.getKey = (variables?: UserPremiumSubscriptionQueryVariables) => variables === undefined ? ['UserPremiumSubscription'] : ['UserPremiumSubscription', variables];

export const useSuspenseUserPremiumSubscriptionQuery = <
      TData = UserPremiumSubscriptionQuery,
      TError = unknown
    >(
      variables?: UserPremiumSubscriptionQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserPremiumSubscriptionQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserPremiumSubscriptionSuspense'] : ['UserPremiumSubscriptionSuspense', variables],
    queryFn: fetcher<UserPremiumSubscriptionQuery, UserPremiumSubscriptionQueryVariables>(UserPremiumSubscriptionDocument, variables),
    ...options
  }
    )};

useSuspenseUserPremiumSubscriptionQuery.getKey = (variables?: UserPremiumSubscriptionQueryVariables) => variables === undefined ? ['UserPremiumSubscriptionSuspense'] : ['UserPremiumSubscriptionSuspense', variables];


useUserPremiumSubscriptionQuery.fetcher = (variables?: UserPremiumSubscriptionQueryVariables, options?: RequestInit['headers']) => fetcher<UserPremiumSubscriptionQuery, UserPremiumSubscriptionQueryVariables>(UserPremiumSubscriptionDocument, variables, options);

export const UserProfileInfoDocument = `
    query UserProfileInfo {
  me(is_me: true) {
    ...UserProfileInfo
  }
}
    ${UserProfileInfoFragmentDoc}`;

export const useUserProfileInfoQuery = <
      TData = UserProfileInfoQuery,
      TError = unknown
    >(
      variables?: UserProfileInfoQueryVariables,
      options?: Omit<UseQueryOptions<UserProfileInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserProfileInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserProfileInfoQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserProfileInfo'] : ['UserProfileInfo', variables],
    queryFn: fetcher<UserProfileInfoQuery, UserProfileInfoQueryVariables>(UserProfileInfoDocument, variables),
    ...options
  }
    )};

useUserProfileInfoQuery.getKey = (variables?: UserProfileInfoQueryVariables) => variables === undefined ? ['UserProfileInfo'] : ['UserProfileInfo', variables];

export const useSuspenseUserProfileInfoQuery = <
      TData = UserProfileInfoQuery,
      TError = unknown
    >(
      variables?: UserProfileInfoQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserProfileInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserProfileInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserProfileInfoQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserProfileInfoSuspense'] : ['UserProfileInfoSuspense', variables],
    queryFn: fetcher<UserProfileInfoQuery, UserProfileInfoQueryVariables>(UserProfileInfoDocument, variables),
    ...options
  }
    )};

useSuspenseUserProfileInfoQuery.getKey = (variables?: UserProfileInfoQueryVariables) => variables === undefined ? ['UserProfileInfoSuspense'] : ['UserProfileInfoSuspense', variables];


useUserProfileInfoQuery.fetcher = (variables?: UserProfileInfoQueryVariables, options?: RequestInit['headers']) => fetcher<UserProfileInfoQuery, UserProfileInfoQueryVariables>(UserProfileInfoDocument, variables, options);
