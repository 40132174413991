import { LOCIZE_PROJECT_ID, REGION_DEFAULT_LANGUAGE } from '@config/envs';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import { AppLanguage } from '../types';

const I18NEXT_LOCAL_STORAGE_KEY = 'i18nextLng';
const LOCAL_STORAGE = 'localStorage';
const QUERY_STRING = 'querystring';
const LOOKUP_QUERY_STRING = 'lang';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: [
			AppLanguage.EN,
			AppLanguage.ET,
			AppLanguage.LV,
			AppLanguage.LT,
			AppLanguage.RU,
		],
		fallbackLng: [REGION_DEFAULT_LANGUAGE, AppLanguage.EN],
		backend: {
			projectId: LOCIZE_PROJECT_ID,
			referenceLng: REGION_DEFAULT_LANGUAGE,
		},
		detection: {
			order: [QUERY_STRING, LOCAL_STORAGE],
			caches: [LOCAL_STORAGE],
			lookupQuerystring: LOOKUP_QUERY_STRING,
			lookupLocalStorage: I18NEXT_LOCAL_STORAGE_KEY,
		},
		debug: false,
	});

export default i18n;
