import * as z from 'zod';
import { AppLanguage, SupportedCountries } from '../types';

const envVariables = z.object({
	VITE_COUNTRY: z.nativeEnum(SupportedCountries),
	NODE_ENV: z.string(),
	VITE_SERVER_URL: z.string(),
	VITE_ID_CARD_AUTHENTICATION_URL: z.string(),
	VITE_LOCIZE_PROJECT_ID: z.string(),
	VITE_REGION_DEFAULT_LANGUAGE: z.nativeEnum(AppLanguage),
	VITE_OLD_APP_DOMAIN: z.string(),
	VITE_PURCHASE_FLOW_DOMAIN: z.string(),
	VITE_GOOGLE_TAG_MANAGER_ID: z.string().optional(),
	VITE_SMARTLOOK_PROJECT_ID: z.string().optional(),
	VITE_SIFT_BEACON_KEY: z.string().optional(),
	VITE_INVOICES_FEATURE: z.string().optional(),
	VITE_PROFILE_FEATURE: z.string().optional(),
	VITE_RUDDERSTACK_WRITE_KEY: z.string().optional(),
	VITE_RUDDERSTACK_DATA_PLANE_URL: z.string().optional(),
});

envVariables.parse(process.env);

declare global {
	namespace NodeJS {
		interface ProcessEnv extends z.infer<typeof envVariables> {}
	}
}

export const SERVER_URL = process.env.VITE_SERVER_URL;
export const GRAPHQL_SERVER_URL = `${SERVER_URL}/graphql`;
export const ID_CARD_AUTHENTICATION_URL =
	process.env.VITE_ID_CARD_AUTHENTICATION_URL;
export const LOCIZE_PROJECT_ID = process.env.VITE_LOCIZE_PROJECT_ID;
export const REGION_DEFAULT_LANGUAGE = process.env.VITE_REGION_DEFAULT_LANGUAGE;
export const ENVIRONMENT = process.env.NODE_ENV;
export const APP_COUNTRY = process.env.VITE_COUNTRY;
export const OLD_APP_DOMAIN = process.env.VITE_OLD_APP_DOMAIN;
export const PURCHASE_FLOW_DOMAIN = process.env.VITE_PURCHASE_FLOW_DOMAIN;
export const GOOGLE_TAG_MANAGER_ID = process.env.VITE_GOOGLE_TAG_MANAGER_ID;
export const SMARTLOOK_PROJECT_ID = process.env.VITE_SMARTLOOK_PROJECT_ID;
export const SENTRY_DSN = process.env.VITE_SENTRY_DSN;
export const SIFT_BEACON_KEY = process.env.VITE_SIFT_BEACON_KEY;
export const INVOICES_FEATURE = process.env.VITE_INVOICES_FEATURE === '1';
export const PROFILE_FEATURE = process.env.VITE_PROFILE_FEATURE === '1';
export const RUDDERSTACK_WRITE_KEY = process.env.VITE_RUDDERSTACK_WRITE_KEY;
export const RUDDERSTACK_DATA_PLANE_URL =
	process.env.VITE_RUDDERSTACK_DATA_PLANE_URL;

export const isProd = ENVIRONMENT === 'production';
