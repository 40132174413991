import { APP_CONFIG } from '@config/app';
import { APP_COUNTRY } from '@config/envs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useAppConfig = () => {
	const { i18n } = useTranslation();

	return useMemo(
		() => ({
			country: APP_COUNTRY,
			authMethods: APP_CONFIG.authMethods,
			starProduct: APP_CONFIG.starProduct,
			supportUrl: APP_CONFIG.supportUrlByLanguage[i18n.language],
			legalInfo: APP_CONFIG.legalInfo,
			termsUrl: APP_CONFIG.termsUrlByLanguage[i18n.language],
			premiumTermsUrl: APP_CONFIG.premiumTermsUrlByLanguage[i18n.language],
			phoneCode: APP_CONFIG.phoneCode,
			isRejectedCAWRedirectionToCamEnabled:
				APP_CONFIG.isRejectedCAWRedirectionToCamEnabled,
			isStandingPaymentEnabled: APP_CONFIG.isStandingPaymentEnabled,
		}),
		[i18n.language],
	);
};
