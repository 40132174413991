import { pricingApi } from '@entities/pricing/api';
import {
	DEFAULT_CREDIT_ACC_MIN_INSTANT_PAYMENT_WITHDRAWAL_AMOUNT,
	DEFAULT_CREDIT_ACC_MIN_WITHDRAWAL_AMOUNT,
} from '@entities/pricing/config';
import { useMemo } from 'react';

const PRICING_KEYS = {
	creditAccMinWithdrawalAmount: 'credit_acc.min_withdrawal_amount',
	creditAccMinInstantWithdrawalAmount:
		'credit_acc.min_instant_withdrawal_amount',
} as const;

export const useWithdrawalAmountPricingConfig = () => {
	const { data } = pricingApi.useSuspensePricingQuery({
		keys: [
			PRICING_KEYS.creditAccMinWithdrawalAmount,
			PRICING_KEYS.creditAccMinInstantWithdrawalAmount,
		],
	});

	return useMemo(() => {
		return (
			data?.pricing?.reduce<{
				minWithdrawalAmount: number;
				minInstantWithdrawalAmount: number;
			}>(
				(acc, p) => {
					if (!p) {
						return acc;
					}
					if (p.key === 'credit_acc.min_withdrawal_amount') {
						acc.minWithdrawalAmount = +p.value;
					}

					if (p.key === 'credit_acc.min_instant_withdrawal_amount') {
						acc.minInstantWithdrawalAmount = +p.value;
					}

					return acc;
				},
				{
					minWithdrawalAmount: DEFAULT_CREDIT_ACC_MIN_WITHDRAWAL_AMOUNT,
					minInstantWithdrawalAmount:
						DEFAULT_CREDIT_ACC_MIN_INSTANT_PAYMENT_WITHDRAWAL_AMOUNT,
				},
			) ?? {
				minWithdrawalAmount: DEFAULT_CREDIT_ACC_MIN_WITHDRAWAL_AMOUNT,
				minInstantWithdrawalAmount:
					DEFAULT_CREDIT_ACC_MIN_INSTANT_PAYMENT_WITHDRAWAL_AMOUNT,
			}
		);
	}, [data?.pricing]);
};
