import { LOCIZE_COMMON_KEYS } from '@config/locize';
import { UserInvoiceState } from './types';

export const InvoiceContainerConfigByVariant = {
	[UserInvoiceState.NEUTRAL]: {
		text: LOCIZE_COMMON_KEYS.invoiceNeutralText,
		className: 'bg-neutral-100',
	},
	[UserInvoiceState.WARNING]: {
		text: LOCIZE_COMMON_KEYS.invoiceWarningText,
		className: 'bg-[#FFE4B1]',
	},
	[UserInvoiceState.OVERDUE]: {
		text: LOCIZE_COMMON_KEYS.invoiceOverdueText,
		className: 'bg-[#FFBFB1]',
	},
} as const;
