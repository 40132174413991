/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UpdatePaymentLeaveMonthPeriodMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
  months: Types.Scalars['Int']['input'];
  paymentLeaveId: Types.Scalars['Int']['input'];
}>;


export type UpdatePaymentLeaveMonthPeriodMutation = { update_payment_leave?: { status: Types.PaymentLeaveStatusType } | null };



export const UpdatePaymentLeaveMonthPeriodDocument = `
    mutation UpdatePaymentLeaveMonthPeriod($userId: Int!, $months: Int!, $paymentLeaveId: Int!) {
  update_payment_leave(
    user_id: $userId
    months: $months
    payment_leave_id: $paymentLeaveId
  ) {
    status
  }
}
    `;

export const useUpdatePaymentLeaveMonthPeriodMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePaymentLeaveMonthPeriodMutation, TError, UpdatePaymentLeaveMonthPeriodMutationVariables, TContext>) => {
    
    return useMutation<UpdatePaymentLeaveMonthPeriodMutation, TError, UpdatePaymentLeaveMonthPeriodMutationVariables, TContext>(
      {
    mutationKey: ['UpdatePaymentLeaveMonthPeriod'],
    mutationFn: (variables?: UpdatePaymentLeaveMonthPeriodMutationVariables) => fetcher<UpdatePaymentLeaveMonthPeriodMutation, UpdatePaymentLeaveMonthPeriodMutationVariables>(UpdatePaymentLeaveMonthPeriodDocument, variables)(),
    ...options
  }
    )};


useUpdatePaymentLeaveMonthPeriodMutation.fetcher = (variables: UpdatePaymentLeaveMonthPeriodMutationVariables, options?: RequestInit['headers']) => fetcher<UpdatePaymentLeaveMonthPeriodMutation, UpdatePaymentLeaveMonthPeriodMutationVariables>(UpdatePaymentLeaveMonthPeriodDocument, variables, options);
