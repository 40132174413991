import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// ✅ globally default to 20 seconds
			staleTime: 20_000,
		},
	},
});
