export const PAYMENT_PROVIDER = {
	getKevinBanklink: 'GETKEVIN_BANKLINK',
	everypayCard: 'EVERYPAY_CARD',
} as const;

export enum AppPaymentMethod {
	BANKLINK = 'banklink',
	CARD = 'card',
	MANUAL_TRANSFER = 'manual_transfer',
}

export enum PaymentMethodKeys {
	MAIN = 'main',
	LEGACY = 'legacy',
}

export enum PaymentMethodType {
	BANK_WIRE = 'BANK_WIRE',
	BANKLINK = 'BANKLINK',
	CARD = 'CARD',
}

export const PAYMENT_PROVIDER_BY_METHODS = {
	[AppPaymentMethod.BANKLINK]: PAYMENT_PROVIDER.getKevinBanklink,
	[AppPaymentMethod.CARD]: PAYMENT_PROVIDER.everypayCard,
	[AppPaymentMethod.MANUAL_TRANSFER]: '',
} as const satisfies Record<AppPaymentMethod, string>;
