import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const profileSearchSchema = z.object({
	showNewsletterPopup: z.boolean().optional().catch(undefined),
});

export const Route = createFileRoute('/_protected/_main/profile')({
	validateSearch: profileSearchSchema,
});
