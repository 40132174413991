import { queryClient } from '@lib/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';

export const withTanStackQuery = (component: () => ReactNode) => () => (
	<QueryClientProvider client={queryClient}>
		{component()}
		<ReactQueryDevtools />
	</QueryClientProvider>
);
