export const generateLinkWithSearchParams = ({
	searchParams,
	link,
}: {
	link: string;
	searchParams: Record<string, unknown>;
}) => {
	if (!link) {
		return '';
	}

	const searchParamsEntries = Object.entries(searchParams);

	if (!searchParamsEntries.length) {
		return link;
	}

	const query = new URLSearchParams();
	for (const item of searchParamsEntries) {
		const [key, value] = item;
		if (!value) {
			continue;
		}

		query.append(
			key,
			typeof value === 'string' ? value : JSON.stringify(value),
		);
	}

	const queryString = query.toString();

	if (!queryString) {
		return link;
	}

	if (link.includes('?')) {
		return `${link}&${queryString}`;
	}

	return `${link}?${queryString}`;
};
