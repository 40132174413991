/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type PricingQueryVariables = Types.Exact<{
  keys: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type PricingQuery = { pricing?: Array<{ key: string, value: string } | null> | null };



export const PricingDocument = `
    query Pricing($keys: [String!]!) {
  pricing(keys: $keys) {
    key
    value
  }
}
    `;

export const usePricingQuery = <
      TData = PricingQuery,
      TError = unknown
    >(
      variables: PricingQueryVariables,
      options?: Omit<UseQueryOptions<PricingQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<PricingQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<PricingQuery, TError, TData>(
      {
    queryKey: ['Pricing', variables],
    queryFn: fetcher<PricingQuery, PricingQueryVariables>(PricingDocument, variables),
    ...options
  }
    )};

usePricingQuery.getKey = (variables: PricingQueryVariables) => ['Pricing', variables];

export const useSuspensePricingQuery = <
      TData = PricingQuery,
      TError = unknown
    >(
      variables: PricingQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<PricingQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<PricingQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<PricingQuery, TError, TData>(
      {
    queryKey: ['PricingSuspense', variables],
    queryFn: fetcher<PricingQuery, PricingQueryVariables>(PricingDocument, variables),
    ...options
  }
    )};

useSuspensePricingQuery.getKey = (variables: PricingQueryVariables) => ['PricingSuspense', variables];


usePricingQuery.fetcher = (variables: PricingQueryVariables, options?: RequestInit['headers']) => fetcher<PricingQuery, PricingQueryVariables>(PricingDocument, variables, options);
