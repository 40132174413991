export const LOCIZE_CREDIT_LINE_PAGE_KEYS = {
	pageTitle: 'page-title',
	pageTabPersonalInfo: 'page.tabs.personal-info',
	pageTabInformation: 'page.tabs.general-info',
	howItWorksHeading: 'how-it-works.heading',
	howItWorksItem1Title: 'how-it-works.item1.title',
	howItWorksItem1Description: 'how-it-works.item1.description',
	howItWorksItem2Title: 'how-it-works.item2.title',
	howItWorksItem2Description: 'how-it-works.item2.description',
	howItWorksItem3Title: 'how-it-works.item3.title',
	howItWorksItem3Description: 'how-it-works.item3.description',
	howItWorksItem4Title: 'how-it-works.item4.title',
	howItWorksItem4Description: 'how-it-works.item4.description',
	faqHeading: 'faq.heading',
	faqItem1Question: 'faq.item1.question',
	faqItem1Answer: 'faq.item1.answer',
	faqItem2Question: 'faq.item2.question',
	faqItem2Answer: 'faq.item2.answer',
	faqItem3Question: 'faq.item3.question',
	faqItem3Answer: 'faq.item3.answer',
	faqItem4Question: 'faq.item4.question',
	faqItem4Answer: 'faq.item4.answer',
	faqItem5Question: 'faq.item5.question',
	faqItem5Answer: 'faq.item5.answer',
	helpdeskLabel: 'helpdesk-label',
	helpdeskLink: 'helpdesk-link',
	detailsHeading: 'details-heading',
	detailsCreditLimit: 'details.credit-limit',
	detailsCreditUsed: 'details.credit-used',
	detailsAvailableToWithdraw: 'details.available-to-withdraw',
	detailsMakePaymentButton: 'details.make-payment-button',
	makePaymentDialogTitle: 'make-payment-dialog.title',
	makePaymentDialogDescription: 'make-payment-dialog.description',
	makePaymentDialogSubmitButton: 'make-payment-dialog.submit-button',
	makePaymentDialogInvoicesButton: 'make-payment-dialog.invoices-button',
	documentsHeading: 'documents.heading',
	documentsTableHeadSigned: 'documents.table.head.signed',
	documentsTableHeadType: 'documents.table.head.type',
	documentsTableContractTypeDefault: 'documents.table.contract.type.default',
	documentsTableContractTypeGracePeriod:
		'documents.table.contract.type.grace-period',
} as const;
