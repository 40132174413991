import { GLOBAL_LOCAL_STORAGE_KEYS } from '@config/common';
import { APP_COUNTRY, SIFT_BEACON_KEY } from '@config/envs';
import { useUserId } from '@entities/user';
import { useRouterState } from '@tanstack/react-router';
import { useCallback, useEffect } from 'react';
import { useMount } from 'react-use';
import { v4 as uuid } from 'uuid';

export const useSiftAnalytics = () => {
	const { data: userId } = useUserId();
	const {
		location: { pathname },
	} = useRouterState();

	const initialize = useCallback(() => {
		if (!SIFT_BEACON_KEY || window._sift) return;

		let sessionId = localStorage.getItem(
			GLOBAL_LOCAL_STORAGE_KEYS.browsingSessionId,
		);

		if (!sessionId) {
			sessionId = uuid();
			localStorage.setItem(
				GLOBAL_LOCAL_STORAGE_KEYS.browsingSessionId,
				sessionId,
			);
		}

		const siftScript = document.createElement('script');
		siftScript.type = 'text/javascript';
		siftScript.src = 'https://cdn.sift.com/s.js';
		document.body.insertAdjacentElement('afterbegin', siftScript);

		window._sift = window._sift || [];

		window._sift.push(['_setSessionId', sessionId]);
		window._sift.push(['_setAccount', SIFT_BEACON_KEY]);
		window._sift.push(['_trackPageview']);
	}, []);

	const identify = useCallback(() => {
		if (!userId)
			throw new Error('[Sift]: Udentify user failed. User id is not defined');

		if (!window._sift) return;

		const userIdentifier = userId ? `${APP_COUNTRY}-${userId}` : null;

		window._sift.push(['_setUserId', userIdentifier]);
	}, [userId]);

	const trackPageView = useCallback(() => {
		if (!window._sift) return;

		window._sift.push(['_trackPageview']);
	}, []);

	useMount(() => {
		initialize();
	});

	useEffect(() => {
		if (!userId) return;

		identify();
	}, [userId, identify]);

	useEffect(() => {
		if (!pathname) return;

		trackPageView();
	}, [pathname, trackPageView]);
};
