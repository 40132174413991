import { LoginPollStatus } from '@/shared/types';
import { APP_CONFIG } from '@config/app';
import { userApi } from '@entities/user';
import { EPARAKSTS_LOGIN_CODE_SEARCH_NAME } from '@features/auth/by-eparaksts';
import {
	PAYSERA_LOGIN_STATUS_SEARCH_NAME,
	PayseraLoginStatus,
} from '@features/auth/by-paysera-banklink/login';
import { LOCAL_STORAGE_SESSION_ID_KEY } from '@hooks/system/useLocalStorageSessionId';
import { serverFetch } from '@lib/serverFetcher';
import { createFileRoute } from '@tanstack/react-router';
import { usePayseraLoginQuery } from '@widgets/auth/paysera-banklink-form/api';
import * as z from 'zod';

const authSearchSchema = z.object({
	authMethod: z.enum(APP_CONFIG.authMethods).catch(APP_CONFIG.authMethods[0]),
	invite: z.string().optional().catch(undefined),
	token: z.string().optional().catch(undefined),
	[PAYSERA_LOGIN_STATUS_SEARCH_NAME]: z
		.nativeEnum(PayseraLoginStatus)
		.optional()
		.catch(undefined),
	redirectUrl: z.string().optional().catch(undefined),
	// TODO: remove after full migration to new app
	redirect_back: z.string().optional().catch(undefined),
	// eparaksts auth code
	[EPARAKSTS_LOGIN_CODE_SEARCH_NAME]: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/auth')({
	beforeLoad: async ({
		search: { payseraLoginStatus },
		context: { queryClient },
	}) => {
		const sessionId = localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY);
		if (payseraLoginStatus === PayseraLoginStatus.SUCCESS && !!sessionId) {
			const data = await serverFetch(usePayseraLoginQuery, {
				variables: {
					session_id: sessionId.replaceAll('"', ''),
				},
			});
			if (data?.status === LoginPollStatus.SUCCESSFUL) {
				await queryClient.refetchQueries({
					queryKey: userApi.useSuspenseUserQuery.getKey(),
				});
			}
		}
	},
	validateSearch: authSearchSchema,
});
