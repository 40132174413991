import { isProd } from '@/shared/config/envs';
import {
	useGoogleTagManagerAnalytics,
	useRudderStackAnalytics,
	useSiftAnalytics,
	useSmartlookAnalytics,
} from '@entities/analytics';
import { userApi } from '@entities/user';
import { ErrorPage } from '@pages/error';
import { type QueryClient, queryOptions } from '@tanstack/react-query';
import {
	Outlet,
	ScrollRestoration,
	createRootRouteWithContext,
	useRouterState,
} from '@tanstack/react-router';
import { Suspense, lazy, useEffect } from 'react';
import * as z from 'zod';

const rootValidateSearchSchema = z.object({
	rl_anonymous_id: z.string().optional().catch(undefined),
});

const TanStackRouterDevtools = isProd
	? () => null
	: lazy(() =>
			import('@tanstack/router-devtools').then((res) => ({
				default: res.TanStackRouterDevtools,
			})),
		);

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
}>()({
	validateSearch: rootValidateSearchSchema,
	beforeLoad: async ({ context: { queryClient } }) => {
		await queryClient.ensureQueryData(
			queryOptions({
				queryKey: userApi.useSuspenseUserQuery.getKey(),
				queryFn: userApi.useUserQuery.fetcher(),
			}),
		);
	},
	component: () => {
		const {
			location: { pathname },
		} = useRouterState();

		useSiftAnalytics();
		useSmartlookAnalytics();
		useGoogleTagManagerAnalytics();
		const { trackPage } = useRudderStackAnalytics();

		useEffect(() => {
			trackPage(pathname);
		}, [pathname, trackPage]);

		return (
			<>
				<ScrollRestoration />
				<Outlet />
				<Suspense>
					<TanStackRouterDevtools />
				</Suspense>
			</>
		);
	},
	errorComponent: ErrorPage,
});
