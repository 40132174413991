import { GRAPHQL_SERVER_URL, SMARTLOOK_PROJECT_ID } from '@config/envs';
import { userApi } from '@entities/user';
import { getFullName } from '@utils/getFullName';
import { useCallback, useEffect } from 'react';
import { useMount } from 'react-use';
import Smartlook from 'smartlook-client';

export const useSmartlookAnalytics = () => {
	const { data: user } = userApi.useSuspenseUserQuery(undefined, {
		select: (data) =>
			data?.me
				? {
						id: data.me.id,
						email: data.me.email || 'No Email',
						name: getFullName({
							firstName: data.me.profile?.first_name,
							lastName: data.me.profile?.last_name,
							defaultValue: 'No Name',
						}),
					}
				: null,
	});

	const initialize = useCallback(() => {
		if (!SMARTLOOK_PROJECT_ID || Smartlook.initialized()) return;

		Smartlook.init(SMARTLOOK_PROJECT_ID, {
			advancedNetwork: {
				allowedUrls: [GRAPHQL_SERVER_URL],
			},
		});

		Smartlook.record({
			forms: true,
			emails: true,
			numbers: true,
			api: true,
			ips: true,
		});
	}, []);

	const identify = useCallback(() => {
		if (!user || !Smartlook.initialized()) return;

		Smartlook.identify(user.id, {
			name: user.name,
			email: user.email,
		});
	}, [user]);

	useMount(() => {
		initialize();
	});

	useEffect(() => {
		if (!user) return;

		identify();
	}, [user, identify]);
};
