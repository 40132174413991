import { UserInvoiceState } from '@entities/invoices/types';
import dayjs from 'dayjs';

export const getInvoiceState = ({
	paymentDate,
	unpaidAmount,
	isStandingPaymentEnabled,
}: {
	paymentDate: string;
	unpaidAmount: number;
	isStandingPaymentEnabled: boolean;
}) => {
	if (unpaidAmount === 0) {
		return null;
	}

	const now = dayjs();
	const nextPaymentDate = dayjs(paymentDate);
	const daysUntilNextPayment = nextPaymentDate.diff(now, 'day') + 1;

	if (daysUntilNextPayment < 0) {
		return UserInvoiceState.OVERDUE;
	}

	// Invoice is overdue
	if (isStandingPaymentEnabled) {
		return null;
	}

	// Invoice is due in 5 days
	if (daysUntilNextPayment >= 0 && daysUntilNextPayment < 6) {
		return UserInvoiceState.WARNING;
	}

	// Invoice is due but still far away
	return UserInvoiceState.NEUTRAL;
};
