import {
	RUDDERSTACK_DATA_PLANE_URL,
	RUDDERSTACK_WRITE_KEY,
} from '@config/envs';
import { rudderStackApi } from '@entities/analytics/api';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { useSearch } from '@tanstack/react-router';
import { useCallback } from 'react';
import { useMount } from 'react-use';

const rudderAnalytics = new RudderAnalytics();

export const useRudderStackAnalytics = () => {
	const { rl_anonymous_id } = useSearch({ strict: false });
	const { mutateAsync } = rudderStackApi.useTrackRudderStackEventMutation();

	const isRudderStackIsInitialized = Boolean(
		RUDDERSTACK_WRITE_KEY &&
			RUDDERSTACK_DATA_PLANE_URL &&
			rudderAnalytics.analyticsInstances[RUDDERSTACK_WRITE_KEY]?.initialized,
	);

	const trackEvent = useCallback(
		({
			event,
			properties = {},
		}: {
			event: string;
			properties?: Record<string, string | number | boolean | Array<unknown>>;
		}) =>
			mutateAsync({
				event_name: event,
				properties: JSON.stringify(properties),
			}),
		[mutateAsync],
	);

	const initialize = () => {
		if (isRudderStackIsInitialized) {
			return;
		}

		rudderAnalytics.load(
			RUDDERSTACK_WRITE_KEY ?? '',
			RUDDERSTACK_DATA_PLANE_URL ?? '',
			{
				plugins: ['StorageEncryption'],
				storage: {
					encryption: {
						version: 'legacy',
					},
				},
			},
		);

		if (rl_anonymous_id) {
			rudderAnalytics.setAnonymousId(rl_anonymous_id);
		}
	};

	const trackPage = useCallback(
		(pageName: string) => {
			if (isRudderStackIsInitialized) {
				rudderAnalytics.page(pageName);
			}
		},
		[isRudderStackIsInitialized],
	);

	useMount(() => {
		initialize();
	});

	return {
		trackEvent,
		trackPage,
	};
};
