import { userApi } from '@entities/user/api';

export const useUserId = () =>
	userApi.useSuspenseUserQuery(undefined, {
		select: (data) => {
			if (!data?.me?.id) throw new Error('User id is missing');

			return data.me.id;
		},
	});
