import {
	type TypographyProps,
	typographyVariants,
} from '@components/typography';
import * as LabelPrimitive from '@radix-ui/react-label';
import * as React from 'react';

const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
		Pick<TypographyProps, 'affects' | 'variant'>
>(({ className, variant, affects, ...props }, ref) => (
	<LabelPrimitive.Root
		ref={ref}
		className={typographyVariants({ variant, affects, className })}
		{...props}
	/>
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
