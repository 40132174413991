/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type CreditAccountWithdrawalsCountQueryVariables = Types.Exact<{
  creditAccountId: Types.Scalars['Int']['input'];
}>;


export type CreditAccountWithdrawalsCountQuery = { credit_account_withdrawals?: { data?: Array<{ id: number } | null> | null } | null };

export type CreditAccountNextInvoiceQueryVariables = Types.Exact<{
  creditAccountId: Types.Scalars['Int']['input'];
  additionalAmount?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type CreditAccountNextInvoiceQuery = { credit_account_next_invoice?: { total: number } | null };



export const CreditAccountWithdrawalsCountDocument = `
    query CreditAccountWithdrawalsCount($creditAccountId: Int!) {
  credit_account_withdrawals(limit: 1, credit_account_id: $creditAccountId) {
    data {
      id
    }
  }
}
    `;

export const useCreditAccountWithdrawalsCountQuery = <
      TData = CreditAccountWithdrawalsCountQuery,
      TError = unknown
    >(
      variables: CreditAccountWithdrawalsCountQueryVariables,
      options?: Omit<UseQueryOptions<CreditAccountWithdrawalsCountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CreditAccountWithdrawalsCountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CreditAccountWithdrawalsCountQuery, TError, TData>(
      {
    queryKey: ['CreditAccountWithdrawalsCount', variables],
    queryFn: fetcher<CreditAccountWithdrawalsCountQuery, CreditAccountWithdrawalsCountQueryVariables>(CreditAccountWithdrawalsCountDocument, variables),
    ...options
  }
    )};

useCreditAccountWithdrawalsCountQuery.getKey = (variables: CreditAccountWithdrawalsCountQueryVariables) => ['CreditAccountWithdrawalsCount', variables];

export const useSuspenseCreditAccountWithdrawalsCountQuery = <
      TData = CreditAccountWithdrawalsCountQuery,
      TError = unknown
    >(
      variables: CreditAccountWithdrawalsCountQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<CreditAccountWithdrawalsCountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<CreditAccountWithdrawalsCountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<CreditAccountWithdrawalsCountQuery, TError, TData>(
      {
    queryKey: ['CreditAccountWithdrawalsCountSuspense', variables],
    queryFn: fetcher<CreditAccountWithdrawalsCountQuery, CreditAccountWithdrawalsCountQueryVariables>(CreditAccountWithdrawalsCountDocument, variables),
    ...options
  }
    )};

useSuspenseCreditAccountWithdrawalsCountQuery.getKey = (variables: CreditAccountWithdrawalsCountQueryVariables) => ['CreditAccountWithdrawalsCountSuspense', variables];


useCreditAccountWithdrawalsCountQuery.fetcher = (variables: CreditAccountWithdrawalsCountQueryVariables, options?: RequestInit['headers']) => fetcher<CreditAccountWithdrawalsCountQuery, CreditAccountWithdrawalsCountQueryVariables>(CreditAccountWithdrawalsCountDocument, variables, options);

export const CreditAccountNextInvoiceDocument = `
    query CreditAccountNextInvoice($creditAccountId: Int!, $additionalAmount: Float) {
  credit_account_next_invoice(
    credit_account_id: $creditAccountId
    additional_amount: $additionalAmount
  ) {
    total
  }
}
    `;

export const useCreditAccountNextInvoiceQuery = <
      TData = CreditAccountNextInvoiceQuery,
      TError = unknown
    >(
      variables: CreditAccountNextInvoiceQueryVariables,
      options?: Omit<UseQueryOptions<CreditAccountNextInvoiceQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CreditAccountNextInvoiceQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CreditAccountNextInvoiceQuery, TError, TData>(
      {
    queryKey: ['CreditAccountNextInvoice', variables],
    queryFn: fetcher<CreditAccountNextInvoiceQuery, CreditAccountNextInvoiceQueryVariables>(CreditAccountNextInvoiceDocument, variables),
    ...options
  }
    )};

useCreditAccountNextInvoiceQuery.getKey = (variables: CreditAccountNextInvoiceQueryVariables) => ['CreditAccountNextInvoice', variables];

export const useSuspenseCreditAccountNextInvoiceQuery = <
      TData = CreditAccountNextInvoiceQuery,
      TError = unknown
    >(
      variables: CreditAccountNextInvoiceQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<CreditAccountNextInvoiceQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<CreditAccountNextInvoiceQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<CreditAccountNextInvoiceQuery, TError, TData>(
      {
    queryKey: ['CreditAccountNextInvoiceSuspense', variables],
    queryFn: fetcher<CreditAccountNextInvoiceQuery, CreditAccountNextInvoiceQueryVariables>(CreditAccountNextInvoiceDocument, variables),
    ...options
  }
    )};

useSuspenseCreditAccountNextInvoiceQuery.getKey = (variables: CreditAccountNextInvoiceQueryVariables) => ['CreditAccountNextInvoiceSuspense', variables];


useCreditAccountNextInvoiceQuery.fetcher = (variables: CreditAccountNextInvoiceQueryVariables, options?: RequestInit['headers']) => fetcher<CreditAccountNextInvoiceQuery, CreditAccountNextInvoiceQueryVariables>(CreditAccountNextInvoiceDocument, variables, options);
