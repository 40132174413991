import { cn } from '@/shared/utils/tailwind';
import { type VariantProps, cva } from 'class-variance-authority';
import { type HTMLAttributes, forwardRef } from 'react';

export const typographyVariants = cva('text-primary-black', {
	variants: {
		variant: {
			xxl: 'text-[5.25rem] leading-[5.25rem] tracking-[-.36rem] uppercase font-black',
			xl: 'text-[4rem] leading-[4rem] tracking-[-.24rem] font-bold',
			l: 'text-[3rem] leading-[3rem] tracking-[-.18rem] font-bold',
			m: 'text-[2.25rem] leading-[2.5rem] tracking-[-.135rem] font-bold',
			s: 'text-[1.75rem] leading-[2rem] tracking-[-.105rem] font-semibold',
			xs: 'text-[1.5rem] leading-[1.75rem] tracking-[-.09rem] font-bold',
			xxs: 'text-[1.25rem] leading-[1.5rem] tracking-[-.05rem] font-semibold',
			'text-l': 'text-[1.125rem] leading-[1.5rem] tracking-[-.03375rem]',
			'text-m': 'text-[1rem] leading-[1.5rem] tracking-[-.02rem]',
			'text-s': 'text-[.875rem] leading-[1.25rem] tracking-[-.00875rem]',
		},
		affects: {
			semibold: 'font-semibold',
			bold: 'font-bold',
		},
	},
	defaultVariants: {
		variant: 'text-m',
	},
});

type HTMLTypography = HTMLHeadingElement | HTMLParagraphElement;

export type TypographyProps = VariantProps<typeof typographyVariants> &
	(HTMLAttributes<HTMLTypography> | HTMLAttributes<HTMLSpanElement>) & {
		tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
		testId?: string;
	};

const Typography = forwardRef<HTMLTypography, TypographyProps>(
	(
		{ className, variant = 'text-m', affects, testId, tag = 'p', ...props },
		ref,
	) => {
		const Comp = tag;

		return (
			<Comp
				ref={ref}
				className={cn(typographyVariants({ variant, affects, className }))}
				data-testid={testId}
				{...props}
			/>
		);
	},
);

Typography.displayName = 'Typography';

export { Typography };
