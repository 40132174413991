import { AppLanguage } from '@/shared/types';
import { LOCIZE_DEALS_KEYS } from '@config/locize';
import { type Deal, DealId } from './types';

export const DEAL: Record<DealId, Deal> = {
	[DealId.PHEROMONE]: {
		id: DealId.PHEROMONE,
		brand: 'Pheromone',
		title: LOCIZE_DEALS_KEYS.pheromone,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO40',
		discount: '-40%',
		imageSrc: '/images/dashboard/deals/pheromone.webp',
		websiteUrl:
			'https://pheromone.ee/?utm_source=esto&utm_medium=shop&utm_campaign=shop',
	},
	[DealId.IRRIGATOR]: {
		id: DealId.IRRIGATOR,
		brand: 'Irrigaator',
		title: LOCIZE_DEALS_KEYS.irrigaator,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO15',
		discount: '-15%',
		imageSrc: '/images/dashboard/deals/irrigaator.webp',
		websiteUrl: 'https://www.irrigaator.ee/ostukorv',
	},
	[DealId.SINISE_VALGUSE]: {
		id: DealId.SINISE_VALGUSE,
		brand: 'Sinise Valguse',
		title: LOCIZE_DEALS_KEYS.siniseValguse,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/sinisevalguseprillid.webp',
		websiteUrl: 'https://sinisevalguseprillid.ee',
	},
	[DealId.SPORTLIK]: {
		id: DealId.SPORTLIK,
		brand: 'Sportlik',
		title: LOCIZE_DEALS_KEYS.sportlik,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/sportlik.webp',
		websiteUrl: 'https://sportlik.ee',
	},
	[DealId.CASEPRO]: {
		id: DealId.CASEPRO,
		brand: 'Casepro',
		title: LOCIZE_DEALS_KEYS.casepro,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/casepro.webp',
		websiteUrl: 'https://casepro.ee',
	},
	[DealId.GOFIT]: {
		id: DealId.GOFIT,
		brand: 'GoFit',
		title: LOCIZE_DEALS_KEYS.goFit,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/go-fit.webp',
		websiteUrl: 'https://gofit.ee',
	},
	[DealId.MOKYKLINE]: {
		id: DealId.MOKYKLINE,
		brand: 'Mokyklines',
		title: LOCIZE_DEALS_KEYS.mokykline,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/mokyklines.webp',
		websiteUrl: 'https://www.mokyklinesuniformos.lt',
	},
	[DealId.WOLT_EE]: {
		id: DealId.WOLT_EE,
		brand: 'Wolt',
		title: LOCIZE_DEALS_KEYS.wolt,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'AFFWOLTEST24',
		discount: '3+3',
		imageSrc: '/images/dashboard/deals/wolt.webp',
		websiteUrl: 'https://clk.tradedoubler.com/click?p=333408&a=3379320',
	},
	[DealId.WOLT_LV]: {
		id: DealId.WOLT_LV,
		brand: 'Wolt',
		title: LOCIZE_DEALS_KEYS.wolt,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'AFFWOLTLVA24',
		discount: '3+3',
		imageSrc: '/images/dashboard/deals/wolt.webp',
		websiteUrl: 'https://clk.tradedoubler.com/click?p=331829&a=3379320',
	},
	[DealId.WOLT_LT]: {
		id: DealId.WOLT_LT,
		brand: 'Wolt',
		title: LOCIZE_DEALS_KEYS.wolt,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'AFFWOLTLTU24',
		discount: '3+3',
		imageSrc: '/images/dashboard/deals/wolt.webp',
		websiteUrl: 'https://clk.tradedoubler.com/click?p=331830&a=3379320',
	},
	[DealId.UPGREAT_EE]: {
		id: DealId.UPGREAT_EE,
		brand: 'Upgreat',
		title: LOCIZE_DEALS_KEYS.upgreat,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/upgreat.webp',
		websiteUrl: {
			[AppLanguage.EN]: 'https://en.upgreat.ee/?ref=ESTO10',
			[AppLanguage.ET]: 'https://upgreat.ee/?ref=ESTO10',
			[AppLanguage.RU]: 'https://ru.upgreat.ee/?ref=ESTO10',
			[AppLanguage.LV]: '',
			[AppLanguage.LT]: '',
		},
	},
	[DealId.UPGREAT_LV]: {
		id: DealId.UPGREAT_LV,
		brand: 'Upgreat',
		title: LOCIZE_DEALS_KEYS.upgreat,
		discountCode: 'ESTO10',
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/upgreat.webp',
		websiteUrl: 'https://upgreat.lv/?ref=ESTO10',
	},
	[DealId.UPGREAT_LT]: {
		id: DealId.UPGREAT_LT,
		brand: 'Upgreat',
		title: LOCIZE_DEALS_KEYS.upgreat,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/upgreat.webp',
		websiteUrl: 'https://upgreat.lt/?ref=ESTO10',
	},
	[DealId.FITLAP]: {
		id: DealId.FITLAP,
		brand: 'Fitlap',
		title: LOCIZE_DEALS_KEYS.fitlap,
		description: LOCIZE_DEALS_KEYS.dialogDescription2,
		discountCode: '',
		discount: '-30%',
		imageSrc: '/images/dashboard/deals/fitlap.webp',
		websiteUrl: 'https://fitlap.ee/s/esto30',
	},
	[DealId.KULDAN]: {
		id: DealId.KULDAN,
		brand: 'Kuldan',
		title: LOCIZE_DEALS_KEYS.kuldan,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO30',
		discount: '-30%',
		imageSrc: '/images/dashboard/deals/kuldan.webp',
		websiteUrl: 'https://kuldan.ee',
	},
};
