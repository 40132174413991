import { GOOGLE_TAG_MANAGER_ID } from '@config/envs';
import { GoogleTagManager } from '@lib/googleTagManager';
import { useCallback, useState } from 'react';
import { useMount } from 'react-use';
export const useGoogleTagManagerAnalytics = () => {
	const [isInitialized, setIsInitialized] = useState(false);

	const initialize = useCallback(() => {
		if (!GOOGLE_TAG_MANAGER_ID || isInitialized) return;

		GoogleTagManager.initialize(GOOGLE_TAG_MANAGER_ID);
		setIsInitialized(true);
	}, [isInitialized]);

	useMount(() => {
		initialize();
	});
};
