/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UserCreditCardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserCreditCardQuery = { me?: { everypay_cards?: Array<{ id: number, cc_token: string, cc_last_four_digits: number } | null> | null } | null };



export const UserCreditCardDocument = `
    query UserCreditCard {
  me(is_me: true) {
    everypay_cards {
      id
      cc_token
      cc_last_four_digits
    }
  }
}
    `;

export const useUserCreditCardQuery = <
      TData = UserCreditCardQuery,
      TError = unknown
    >(
      variables?: UserCreditCardQueryVariables,
      options?: Omit<UseQueryOptions<UserCreditCardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserCreditCardQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserCreditCardQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditCard'] : ['UserCreditCard', variables],
    queryFn: fetcher<UserCreditCardQuery, UserCreditCardQueryVariables>(UserCreditCardDocument, variables),
    ...options
  }
    )};

useUserCreditCardQuery.getKey = (variables?: UserCreditCardQueryVariables) => variables === undefined ? ['UserCreditCard'] : ['UserCreditCard', variables];

export const useSuspenseUserCreditCardQuery = <
      TData = UserCreditCardQuery,
      TError = unknown
    >(
      variables?: UserCreditCardQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserCreditCardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserCreditCardQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserCreditCardQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditCardSuspense'] : ['UserCreditCardSuspense', variables],
    queryFn: fetcher<UserCreditCardQuery, UserCreditCardQueryVariables>(UserCreditCardDocument, variables),
    ...options
  }
    )};

useSuspenseUserCreditCardQuery.getKey = (variables?: UserCreditCardQueryVariables) => variables === undefined ? ['UserCreditCardSuspense'] : ['UserCreditCardSuspense', variables];


useUserCreditCardQuery.fetcher = (variables?: UserCreditCardQueryVariables, options?: RequestInit['headers']) => fetcher<UserCreditCardQuery, UserCreditCardQueryVariables>(UserCreditCardDocument, variables, options);
