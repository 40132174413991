import { GRACE_PERIOD_MONTHS_OPTIONS } from '@features/grace-period-period-select';
import { GracePeriodFormView } from '@pages/grace-period';
import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const GracePeriodSearchSchema = z.object({
	view: z.nativeEnum(GracePeriodFormView).optional().catch(undefined),
	period: z.enum(GRACE_PERIOD_MONTHS_OPTIONS).optional().catch(undefined),
});

export const Route = createFileRoute('/_protected/grace-period/')({
	validateSearch: GracePeriodSearchSchema,
});
