import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
	const { theme = 'system' } = useTheme();

	return (
		<Sonner
			className="toaster group"
			theme={theme as ToasterProps['theme']}
			toastOptions={{
				classNames: {
					toast:
						'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg !gap-1',
					title:
						'group-[.toast]:text-[.875rem] group-[.toast]:!leading-[1.125rem] group-[.toast]:tracking-[-.00875rem] group-[.toast]:font-semibold',
					description:
						'group-[.toast]:text-[.875rem] !leading-[1.125rem] group-[.toast]:!tracking-[-.00875rem] group-[.toast]:font-normal',
					actionButton:
						'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground !h-8 !px-4 !rounded-[6.25rem] !font-normal !text-[0.875rem] !leading-6 !tracking-[-.00875rem]',
					cancelButton:
						'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
				},
			}}
			{...props}
		/>
	);
};

export { Toaster };
