import { AppAuthMethod } from '@/shared/types';

export const LOCIZE_AUTH_KEYS = {
	authMethods: {
		[AppAuthMethod.SMART_ID]: 'auth-methods.smart-id',
		[AppAuthMethod.MOBILE]: 'auth-methods.mobile-id',
		[AppAuthMethod.ID_CARD]: 'auth-methods.id-card',
		[AppAuthMethod.PAYSERA_BANKLINK]: 'auth-methods.banklink',
		[AppAuthMethod.PASSWORD]: 'auth-methods.password',
		[AppAuthMethod.EPARAKSTS_MOBILE]: 'auth-methods.eparaksts-mobile',
		[AppAuthMethod.EPARAKSTS_SMARTCARD]: 'auth-methods.eparaksts-smartcard',
	},
	authFormTitle: 'auth-form-title',
	authPageTitle: 'auth-page-title',
	cancel: 'cancel',
	carouselActionButton: 'carousel-action-button',
	consumerLoansBadge: 'consumer-loans-badge',
	consumerLoansDescription: 'consumer-loans-description',
	consumerLoansTitle: 'consumer-loans-title',
	continue: 'continue',
	creditLineBadge: 'credit-line-badge',
	creditLineDescription: 'credit-line-description',
	creditLineTitle: 'credit-line-title',
	estoPremiumBadge: 'esto-premium-badge',
	estoPremiumDescription: 'esto-premium-description',
	estoPremiumTitle: 'esto-premium-title',
	idCardInstruction: 'id-card-instruction',
	idCode: 'id-code',
	smartIdCallout: 'smart-id-callout',
	loanInsuranceBadge: 'loan-insurance-badge',
	loanInsuranceDescription: 'loan-insurance-description',
	loanInsuranceTitle: 'loan-insurance-title',
	mobileNumber: 'mobile-number',
	termsInfo: 'terms-info',
	verificationCodeMessage1: 'verification-code-message-1',
	verificationCodeMessage2: 'verification-code-message-2',
	payseraEmailRequest: 'paysera-email-request',
	payseraMagicLinkPending: 'paysera-magic-link-pending',
	supportLink: 'support-link',
	commonError: 'common-error',
	errorTitle: 'error.title',
} as const;
