import { Tabs, TabsContent, TabsList, TabsTrigger } from '@components/ui/tabs';
import {
	LOCIZE_CREDIT_LINE_PAGE_KEYS,
	LOCIZE_NAMESPACES,
} from '@config/locize';
import { useUserCreditAccount } from '@entities/user';
import { CreditLineTabs } from '@pages/credit-line/config';
import { CreditLineBlockSkeleton } from '@pages/credit-line/ui/credit-line-block-skeleton';
import { getRouteApi } from '@tanstack/react-router';
import { type FC, Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreditLinePage.module.css';

const CreditLineGeneralInfo = lazy(() =>
	import('./credit-line-general-info').then((module) => ({
		default: module.CreditLineGeneralInfo,
	})),
);
const CreditLinePersonalInfo = lazy(() =>
	import('./credit-line-personal-info').then((module) => ({
		default: module.CreditLinePersonalInfo,
	})),
);

const CREDIT_LINE_TABS = [
	{
		id: CreditLineTabs.MY_CREDIT_LINE,
		label: LOCIZE_CREDIT_LINE_PAGE_KEYS.pageTabPersonalInfo,
		component: CreditLinePersonalInfo,
	},
	{
		id: CreditLineTabs.INFORMATION,
		label: LOCIZE_CREDIT_LINE_PAGE_KEYS.pageTabInformation,
		component: CreditLineGeneralInfo,
	},
] as const satisfies Array<{
	id: CreditLineTabs;
	label: string;
	component: FC;
}>;

const routeApi = getRouteApi('/_protected/_main/credit-line');

export const CreditLinePageContent = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.creditLinePage);

	const navigate = routeApi.useNavigate();
	const { tab } = routeApi.useSearch();
	const { data: creditAccount } = useUserCreditAccount();

	const onTabChange = (value: string) => {
		navigate({
			search: {
				tab: value as CreditLineTabs,
			},
		});
	};

	return creditAccount?.isActive ? (
		<Tabs
			defaultValue={tab ?? CreditLineTabs.MY_CREDIT_LINE}
			onValueChange={onTabChange}
			className={styles.tabsContainer}
		>
			<TabsList className="md:w-max">
				{CREDIT_LINE_TABS.map((tab) => (
					<TabsTrigger key={tab.id} value={tab.id}>
						{t(tab.label)}
					</TabsTrigger>
				))}
			</TabsList>
			<Suspense fallback={<CreditLineBlockSkeleton />}>
				{CREDIT_LINE_TABS.map((tab) => (
					<TabsContent key={tab.id} value={tab.id}>
						<tab.component />
					</TabsContent>
				))}
			</Suspense>
		</Tabs>
	) : (
		<Suspense fallback={<CreditLineBlockSkeleton />}>
			<CreditLineGeneralInfo className="px-6 pt-8 pb-10 md:p-0" />
		</Suspense>
	);
};
