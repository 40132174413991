import { AppAuthMethod, EparakstsAuthorizationMethod } from '@/shared/types';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { Button } from '@components/ui/button';
import { LOCIZE_AUTH_KEYS, LOCIZE_NAMESPACES } from '@config/locize';
import { useNavigate } from '@tanstack/react-router';
import { generateLinkWithSearchParams } from '@utils/generateLinkWithSearchParams';
import { type FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { eparakstsAuthApi } from '../api';
import { AUTH_FULL_LINK } from '../config';

const RETURN_URL_BY_EPARAKSTS_AUTH_METHOD: Record<
	EparakstsAuthorizationMethod,
	string
> = {
	[EparakstsAuthorizationMethod.MOBILE]: generateLinkWithSearchParams({
		link: AUTH_FULL_LINK,
		searchParams: {
			authMethod: AppAuthMethod.EPARAKSTS_MOBILE,
		},
	}),
	[EparakstsAuthorizationMethod.SMARTCARD]: generateLinkWithSearchParams({
		link: AUTH_FULL_LINK,
		searchParams: {
			authMethod: AppAuthMethod.EPARAKSTS_SMARTCARD,
		},
	}),
};

type EparakstsAuthFormProps = {
	method: EparakstsAuthorizationMethod;
	onSuccess?: () => Promise<void>;
	onError?: () => void;
	loginCode?: string;
};

export const EparakstsAuthForm: FC<EparakstsAuthFormProps> = ({
	method,
	onSuccess,
	onError,
	loginCode,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation(LOCIZE_NAMESPACES.auth);

	const {
		mutateAsync: eparakstsLoginChallengeMutation,
		isPending: isEparakstsLoginChallengeMutationLoading,
	} = eparakstsAuthApi.useEparakstsLoginChallengeMutation();

	const { isFetching, data, isFetched, error } =
		eparakstsAuthApi.useEparakstsLoginQuery(
			{
				code: loginCode ?? '',
				return_url: RETURN_URL_BY_EPARAKSTS_AUTH_METHOD[method],
			},
			{
				enabled: !!loginCode,
			},
		);

	const handleFormSubmit = useCallback(async () => {
		try {
			const data = await eparakstsLoginChallengeMutation({
				method,
				return_url: RETURN_URL_BY_EPARAKSTS_AUTH_METHOD[method],
			});
			if (!data?.eparaksts_login_challenge) {
				throw new Error('No data');
			}

			// if return url doesn't start with http or https, then throw error
			if (!data.eparaksts_login_challenge.redirect_url.startsWith('http')) {
				throw new Error('Invalid redirect url');
			}

			window.open(data.eparaksts_login_challenge.redirect_url);
		} catch {
			onError?.();
		}
	}, [eparakstsLoginChallengeMutation, onError, method]);

	useEffect(() => {
		if (!isFetched) return;
		if (!data?.eparaksts_login?.is_authenticated) {
			onError?.();
			navigate({
				replace: true,
				search: (prev) => ({ ...prev, code: undefined }),
			});
			return;
		}

		onSuccess?.();
	}, [
		isFetched,
		data?.eparaksts_login?.is_authenticated,
		onSuccess,
		navigate,
		onError,
	]);

	useEffect(() => {
		if (error) {
			onError?.();
			navigate({
				replace: true,
				search: (prev) => ({ ...prev, code: undefined }),
			});
		}
	}, [error, onError, navigate]);

	if (isFetching) {
		return (
			<div className="flex w-full flex-col items-center">
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div className="grid w-full max-w-80 gap-14">
			<Button
				fullWidth
				loading={isEparakstsLoginChallengeMutationLoading}
				onClick={handleFormSubmit}
			>
				{t(LOCIZE_AUTH_KEYS.continue)}
			</Button>
		</div>
	);
};
