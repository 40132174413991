import { routeTree } from '@/routeTree.gen';
import { PendingScreen } from '@components/pending-screens/PendingScreen';
import { queryClient } from '@lib/queryClient';
import { NotFoundPage } from '@pages/404';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Suspense } from 'react';

export const router = createRouter({
	routeTree,
	notFoundMode: 'root',
	defaultNotFoundComponent: NotFoundPage,
	context: {
		queryClient,
	},
	defaultPendingComponent: PendingScreen,
});

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}

export const AppRouter = () => (
	<Suspense>
		<RouterProvider router={router} />
	</Suspense>
);
