import { LateSuspense } from '@components/LateSuspense';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { Typography } from '@components/typography';
import { Button } from '@components/ui/button';
import { LOCIZE_NAMESPACES } from '@config/locize';
import { RouteName } from '@config/routes';
import { useUserId } from '@entities/user';
import {
	GracePeriodPeriodSelect,
	type GracePeriodPeriodSelectProps,
} from '@features/grace-period-period-select';
import { useIsMobileView } from '@hooks/system';
import { gracePeriodPageApi } from '@pages/grace-period/api';
import { GracePeriodFormView } from '@pages/grace-period/config';
import { getRouteApi } from '@tanstack/react-router';
import { DualPanelLayout } from '@widgets/layouts/dual-panel';
import { type FC, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styles from './GracePeriodPage.module.css';

const GracePeriodInfo = lazy(() =>
	import('./grace-period-info').then((module) => ({
		default: module.GracePeriodInfo,
	})),
);
const GracePeriodSigning = lazy(() =>
	import('./grace-period-signing').then((module) => ({
		default: module.GracePeriodSigning,
	})),
);

const routerApi = getRouteApi('/_protected/grace-period/');

export const GracePeriodPage: FC = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.gracePeriod);

	const isMobileView = useIsMobileView();

	return (
		<>
			<Helmet title={t('Grace period')} />
			<DualPanelLayout
				left={isMobileView ? null : <GracePeriodInfo />}
				right={<GracePeriodRightPanel />}
				fromPage={RouteName.INVOICES}
			/>
		</>
	);
};

function GracePeriodRightPanel() {
	const { t } = useTranslation(LOCIZE_NAMESPACES.gracePeriod);

	const { data: userId } = useUserId();
	const { data: gracePeriodId } =
		gracePeriodPageApi.useSuspensePendingGracePeriodQuery(undefined, {
			select: (data) => {
				if (!data?.me?.pending_payment_leave?.id)
					throw new Error('Grace period id is missing');

				return data.me.pending_payment_leave.id;
			},
		});

	const isMobileView = useIsMobileView();
	const { view = GracePeriodFormView.OVERVIEW, period } = routerApi.useSearch();
	const navigate = routerApi.useNavigate();

	const handlePeriodSelectChange: GracePeriodPeriodSelectProps['onChange'] = (
		period,
	) => {
		navigate({
			search: (search) => ({
				...search,
				period,
			}),
		});
	};

	return (
		<div className={styles.rightPanel}>
			<Typography variant="s" tag="h1">
				{t('Apply for grace period')}
			</Typography>
			<div className={styles.rightPanelContent}>
				<LateSuspense fallback={<LoadingSpinner className="mx-auto" />}>
					{isMobileView ? (
						view === GracePeriodFormView.OVERVIEW ? (
							<>
								<GracePeriodInfo />
								<Button
									className={styles.continueButton}
									onClick={() =>
										navigate({
											search: (search) => ({
												...search,
												view: GracePeriodFormView.SIGNING,
											}),
										})
									}
								>
									{t('Continue')}
								</Button>
							</>
						) : (
							<>
								<GracePeriodPeriodSelect
									value={period}
									userId={userId}
									paymentLeaveId={gracePeriodId}
									onChange={handlePeriodSelectChange}
								/>
								<GracePeriodSigning />
							</>
						)
					) : (
						<>
							<GracePeriodPeriodSelect
								value={period}
								userId={userId}
								paymentLeaveId={gracePeriodId}
								onChange={handlePeriodSelectChange}
							/>
							<GracePeriodSigning />
						</>
					)}
				</LateSuspense>
			</div>
		</div>
	);
}
