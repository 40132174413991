import { useLocalStorage } from 'react-use';

export const LOCAL_STORAGE_SESSION_ID_KEY = 'sessionId';

export const useLocalStorageSessionId = () => {
	const [sessionId, setLocalStorageSessionId] = useLocalStorage<string>(
		LOCAL_STORAGE_SESSION_ID_KEY,
		'',
	);

	return { sessionId: sessionId ?? '', setLocalStorageSessionId };
};
