import * as ProgressPrimitive from '@radix-ui/react-progress';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

type ProgressVariant = 'black' | 'blue';

export const progressVariants = cva(
	'relative w-full overflow-hidden rounded-[.625rem] bg-neutral-200',
	{
		variants: {
			size: {
				l: 'h-5 border-4 border-neutral-200',
				m: 'h-1.5',
			},
		},
		defaultVariants: {
			size: 'm',
		},
	},
);

const progressIndicatorVariants = cva<{
	variant: Record<ProgressVariant, string>;
}>(
	'h-full w-full flex-1 rounded-full bg-primary transition-none transition-transform ease-in',
	{
		variants: {
			variant: {
				black: 'bg-primary-black',
				blue: 'bg-primary-brand02',
			},
		},
		defaultVariants: {
			variant: 'black',
		},
	},
);

type ProgressVariants = VariantProps<typeof progressVariants>;

export type ProgressProps = React.ComponentPropsWithoutRef<
	typeof ProgressPrimitive.Root
> &
	ProgressVariants & {
		indicatorClassName?: string;
		variant?: ProgressVariant;
	};

const Progress = React.forwardRef<
	React.ElementRef<typeof ProgressPrimitive.Root>,
	ProgressProps
>(({ className, value, variant, size, indicatorClassName, ...props }, ref) => {
	return (
		<ProgressPrimitive.Root
			ref={ref}
			className={progressVariants({ size, className })}
			{...props}
		>
			<ProgressPrimitive.Indicator
				className={progressIndicatorVariants({
					variant,
					className: indicatorClassName,
				})}
				style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
			/>
		</ProgressPrimitive.Root>
	);
});
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
