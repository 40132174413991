import { SupportedCountries } from '@/shared/types';
import { APP_COUNTRY } from '@config/envs';
import { LOCIZE_CREDIT_LINE_PAGE_KEYS } from '@config/locize';

export enum CreditLineTabs {
	MY_CREDIT_LINE = 'my-credit-line',
	INFORMATION = 'information',
}

export const CREDIT_LINE_HOW_IT_WORK = {
	apply: {
		imageSrc: '/images/credit-line/how-it-works/apply.webp',
		title: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem1Title,
		description: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem1Description,
	},
	payWhatUse: {
		imageSrc: '/images/credit-line/how-it-works/pay-what-use.webp',
		title: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem2Title,
		description: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem2Description,
	},
	monthlyPayments: {
		imageSrc: '/images/credit-line/how-it-works/monthly-payments.webp',
		title: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem3Title,
		description: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem3Description,
	},
	replanish: {
		imageSrc: '/images/credit-line/how-it-works/replanish.webp',
		title: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem4Title,
		description: LOCIZE_CREDIT_LINE_PAGE_KEYS.howItWorksItem4Description,
	},
} as const satisfies Record<
	string,
	{
		imageSrc: string;
		title: string;
		description: string;
	}
>;

export const CREDIT_LINE_HOW_IT_WORKS_BY_COUNTRY = {
	[SupportedCountries.EE]: [
		CREDIT_LINE_HOW_IT_WORK.apply,
		CREDIT_LINE_HOW_IT_WORK.payWhatUse,
		CREDIT_LINE_HOW_IT_WORK.monthlyPayments,
		CREDIT_LINE_HOW_IT_WORK.replanish,
	],
	[SupportedCountries.LV]: [
		CREDIT_LINE_HOW_IT_WORK.apply,
		CREDIT_LINE_HOW_IT_WORK.payWhatUse,
		CREDIT_LINE_HOW_IT_WORK.monthlyPayments,
		CREDIT_LINE_HOW_IT_WORK.replanish,
	],
	[SupportedCountries.LT]: [
		CREDIT_LINE_HOW_IT_WORK.apply,
		CREDIT_LINE_HOW_IT_WORK.payWhatUse,
		CREDIT_LINE_HOW_IT_WORK.replanish,
	],
} as const;

export const CREDIT_LINE_HOW_IT_WORKS =
	CREDIT_LINE_HOW_IT_WORKS_BY_COUNTRY[APP_COUNTRY];

export const CREDIT_LINE_FAQ = [
	{
		question: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem1Question,
		answer: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem1Answer,
	},
	{
		question: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem2Question,
		answer: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem2Answer,
	},
	{
		question: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem3Question,
		answer: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem3Answer,
	},
	{
		question: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem4Question,
		answer: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem4Answer,
	},
	{
		question: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem5Question,
		answer: LOCIZE_CREDIT_LINE_PAGE_KEYS.faqItem5Answer,
	},
] as const satisfies Array<{
	question: string;
	answer: string;
}>;
