import { cn } from '@/shared/utils/tailwind';
import { InfoTooltip } from '@components/InfoTooltip';
import * as React from 'react';
import type { ReactNode } from 'react';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
	dataTestId?: string;
	after?: ReactNode | string;
	info?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, dataTestId, after, info, ...props }, ref) => {
		return (
			<div
				className={cn(
					'flex w-full justify-between rounded-[.875rem] border border-transparent bg-neutral-50 px-3.5 text-base text-primary-black ring-offset-background file:border-0 file:bg-transparent file:font-normal file:text-sm placeholder:text-neutral-400 disabled:cursor-not-allowed [&:has(input:focus-visible)]:border-primary-black [&:has(input[aria-invalid=true])]:border-system-red [&:has(input[aria-invalid=true]:focus-visible)]:border-system-red',
					className,
				)}
			>
				<input
					className={cn(
						'h-12 w-full bg-transparent py-3 focus-visible:outline-none',
						info && 'pr-3.5',
					)}
					ref={ref}
					data-testid={dataTestId}
					type={type}
					{...props}
				/>
				{after ? after : null}
				{info ? (
					<div className="flex items-center">
						<InfoTooltip
							className={cn(
								'w-fit max-w-72',
								props.disabled && 'cursor-not-allowed',
							)}
							text={info}
							iconClassName="h-4 w-4 text-neutral-400"
						/>
					</div>
				) : null}
			</div>
		);
	},
);
Input.displayName = 'Input';

export { Input };
