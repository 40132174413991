import { SupportedCountries } from '@/shared/types';
import { APP_COUNTRY } from '@config/envs';
import { DealId } from '@entities/deals';

export const DEALS_IDS_BY_COUNTRY = {
	[SupportedCountries.EE]: [
		DealId.KULDAN,
		DealId.UPGREAT_EE,
		DealId.FITLAP,
		DealId.WOLT_EE,
		DealId.PHEROMONE,
		DealId.IRRIGATOR,
		DealId.SINISE_VALGUSE,
		DealId.SPORTLIK,
		DealId.CASEPRO,
		DealId.GOFIT,
	],
	[SupportedCountries.LV]: [
		DealId.UPGREAT_LV,
		DealId.WOLT_LV,
		DealId.GOFIT,
		DealId.CASEPRO,
		DealId.SINISE_VALGUSE,
		DealId.SPORTLIK,
	],
	[SupportedCountries.LT]: [
		DealId.UPGREAT_LT,
		DealId.WOLT_LT,
		DealId.MOKYKLINE,
		DealId.GOFIT,
		DealId.CASEPRO,
		DealId.SINISE_VALGUSE,
	],
} as const satisfies Record<SupportedCountries, Array<DealId>>;

export const DEALS_IDS = DEALS_IDS_BY_COUNTRY[APP_COUNTRY];

export const RUDDERSTACK_DEALS_EVENTS = {
	CUSTOMER_PROFILE_ESTO_DEAL_CLICKED: 'CUSTOMER_PROFILE_ESTO_DEAL_CLICKED',
	CUSTOMER_PROFILE_ESTO_DEALS_VIEW: 'CUSTOMER_PROFILE_ESTO_DEALS_VIEW',
} as const;
