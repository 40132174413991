import { GRAPHQL_SERVER_URL } from '@config/envs';

export const fetcher = <TData, TVariables>(
	query: string,
	variables?: TVariables,
	options?: HeadersInit,
) => {
	return async (): Promise<TData> => {
		const res = await fetch(GRAPHQL_SERVER_URL, {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...options,
			},
			body: JSON.stringify({ query, variables }),
		});

		const json = await res.json();

		if (json.errors) {
			throw json.errors;
		}

		return json.data;
	};
};
