import { CreditAccountStatus } from '@/shared/types';
import { userApi } from '@entities/user/api';

export const useUserCreditAccount = () =>
	userApi.useSuspenseUserCreditAccountQuery(undefined, {
		select: (data) => {
			const creditAccount = data?.me?.credit_accounts?.at(0);

			if (!creditAccount) {
				return null;
			}

			return {
				id: creditAccount.id,
				status: creditAccount.status,
				creditLimit: creditAccount.credit_limit,
				unpaidPrincipal: creditAccount.unpaid_principal,
				canRequestLimitIncrease: creditAccount.can_request_limit_increase,
				signedAt: creditAccount.signed_at,
				isActive: creditAccount.status === CreditAccountStatus.ACTIVE,
				shouldBeModified: creditAccount.should_be_modified,
			};
		},
	});
