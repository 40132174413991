/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type CardPaymentCalculationQueryVariables = Types.Exact<{
  amount: Types.Scalars['Float']['input'];
  paymentProvider: Types.Scalars['String']['input'];
}>;


export type CardPaymentCalculationQuery = { card_payment_fee?: { amount: number, amount_with_fee: number, fee: number } | null };

export type PaymentMethodsQueryVariables = Types.Exact<{
  countryCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  testMode?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  directPayment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type PaymentMethodsQuery = { payment_methods?: Array<{ key: string, logo_url: string, name: string, type: string, iban?: string | null, beneficiary_name?: string | null, swift?: string | null } | null> | null };

export type GetkevinBanklinkPaymentQueryVariables = Types.Exact<{
  getkevinRequestId: Types.Scalars['String']['input'];
  hash: Types.Scalars['String']['input'];
}>;


export type GetkevinBanklinkPaymentQuery = { payment?: { status_group?: Types.GetkevinStatusGroupType | null } | null };



export const CardPaymentCalculationDocument = `
    query CardPaymentCalculation($amount: Float!, $paymentProvider: String!) {
  card_payment_fee(amount: $amount, payment_provider: $paymentProvider) {
    amount
    amount_with_fee
    fee
  }
}
    `;

export const useCardPaymentCalculationQuery = <
      TData = CardPaymentCalculationQuery,
      TError = unknown
    >(
      variables: CardPaymentCalculationQueryVariables,
      options?: Omit<UseQueryOptions<CardPaymentCalculationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CardPaymentCalculationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CardPaymentCalculationQuery, TError, TData>(
      {
    queryKey: ['CardPaymentCalculation', variables],
    queryFn: fetcher<CardPaymentCalculationQuery, CardPaymentCalculationQueryVariables>(CardPaymentCalculationDocument, variables),
    ...options
  }
    )};

useCardPaymentCalculationQuery.getKey = (variables: CardPaymentCalculationQueryVariables) => ['CardPaymentCalculation', variables];

export const useSuspenseCardPaymentCalculationQuery = <
      TData = CardPaymentCalculationQuery,
      TError = unknown
    >(
      variables: CardPaymentCalculationQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<CardPaymentCalculationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<CardPaymentCalculationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<CardPaymentCalculationQuery, TError, TData>(
      {
    queryKey: ['CardPaymentCalculationSuspense', variables],
    queryFn: fetcher<CardPaymentCalculationQuery, CardPaymentCalculationQueryVariables>(CardPaymentCalculationDocument, variables),
    ...options
  }
    )};

useSuspenseCardPaymentCalculationQuery.getKey = (variables: CardPaymentCalculationQueryVariables) => ['CardPaymentCalculationSuspense', variables];


useCardPaymentCalculationQuery.fetcher = (variables: CardPaymentCalculationQueryVariables, options?: RequestInit['headers']) => fetcher<CardPaymentCalculationQuery, CardPaymentCalculationQueryVariables>(CardPaymentCalculationDocument, variables, options);

export const PaymentMethodsDocument = `
    query PaymentMethods($countryCode: String, $testMode: Boolean, $directPayment: Boolean) {
  payment_methods(
    country_code: $countryCode
    test_mode: $testMode
    direct_payment: $directPayment
  ) {
    key
    logo_url
    name
    type
    iban
    beneficiary_name
    swift
  }
}
    `;

export const usePaymentMethodsQuery = <
      TData = PaymentMethodsQuery,
      TError = unknown
    >(
      variables?: PaymentMethodsQueryVariables,
      options?: Omit<UseQueryOptions<PaymentMethodsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<PaymentMethodsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<PaymentMethodsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['PaymentMethods'] : ['PaymentMethods', variables],
    queryFn: fetcher<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables),
    ...options
  }
    )};

usePaymentMethodsQuery.getKey = (variables?: PaymentMethodsQueryVariables) => variables === undefined ? ['PaymentMethods'] : ['PaymentMethods', variables];

export const useSuspensePaymentMethodsQuery = <
      TData = PaymentMethodsQuery,
      TError = unknown
    >(
      variables?: PaymentMethodsQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<PaymentMethodsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<PaymentMethodsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<PaymentMethodsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['PaymentMethodsSuspense'] : ['PaymentMethodsSuspense', variables],
    queryFn: fetcher<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables),
    ...options
  }
    )};

useSuspensePaymentMethodsQuery.getKey = (variables?: PaymentMethodsQueryVariables) => variables === undefined ? ['PaymentMethodsSuspense'] : ['PaymentMethodsSuspense', variables];


usePaymentMethodsQuery.fetcher = (variables?: PaymentMethodsQueryVariables, options?: RequestInit['headers']) => fetcher<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables, options);

export const GetkevinBanklinkPaymentDocument = `
    query GetkevinBanklinkPayment($getkevinRequestId: String!, $hash: String!) {
  payment: getkevin_banklink_payment(
    getkevin_request_id: $getkevinRequestId
    hash: $hash
  ) {
    status_group
  }
}
    `;

export const useGetkevinBanklinkPaymentQuery = <
      TData = GetkevinBanklinkPaymentQuery,
      TError = unknown
    >(
      variables: GetkevinBanklinkPaymentQueryVariables,
      options?: Omit<UseQueryOptions<GetkevinBanklinkPaymentQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetkevinBanklinkPaymentQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetkevinBanklinkPaymentQuery, TError, TData>(
      {
    queryKey: ['GetkevinBanklinkPayment', variables],
    queryFn: fetcher<GetkevinBanklinkPaymentQuery, GetkevinBanklinkPaymentQueryVariables>(GetkevinBanklinkPaymentDocument, variables),
    ...options
  }
    )};

useGetkevinBanklinkPaymentQuery.getKey = (variables: GetkevinBanklinkPaymentQueryVariables) => ['GetkevinBanklinkPayment', variables];

export const useSuspenseGetkevinBanklinkPaymentQuery = <
      TData = GetkevinBanklinkPaymentQuery,
      TError = unknown
    >(
      variables: GetkevinBanklinkPaymentQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<GetkevinBanklinkPaymentQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<GetkevinBanklinkPaymentQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<GetkevinBanklinkPaymentQuery, TError, TData>(
      {
    queryKey: ['GetkevinBanklinkPaymentSuspense', variables],
    queryFn: fetcher<GetkevinBanklinkPaymentQuery, GetkevinBanklinkPaymentQueryVariables>(GetkevinBanklinkPaymentDocument, variables),
    ...options
  }
    )};

useSuspenseGetkevinBanklinkPaymentQuery.getKey = (variables: GetkevinBanklinkPaymentQueryVariables) => ['GetkevinBanklinkPaymentSuspense', variables];


useGetkevinBanklinkPaymentQuery.fetcher = (variables: GetkevinBanklinkPaymentQueryVariables, options?: RequestInit['headers']) => fetcher<GetkevinBanklinkPaymentQuery, GetkevinBanklinkPaymentQueryVariables>(GetkevinBanklinkPaymentDocument, variables, options);
