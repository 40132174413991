import { invoicesApi } from '@entities/invoices/api';
import { UserInvoiceState } from '@entities/invoices/types';
import { getInvoiceState } from '@entities/invoices/utils';

export const useUserRecentInvoice = () =>
	invoicesApi.useSuspenseUserInvoiceQuery(undefined, {
		select: (data) => {
			if (!data?.me?.recent_invoice) {
				return null;
			}

			return {
				isOverdue:
					getInvoiceState({
						paymentDate: data.me.recent_invoice.due_at,
						unpaidAmount: data.me.unpaid_invoice_amount,
						isStandingPaymentEnabled: !!data.me.subscription?.id,
					}) === UserInvoiceState.OVERDUE,
				isPaid: data.me.unpaid_invoice_amount === 0,
				url: data.me.recent_invoice.url,
				nextPaymentDate: data.me.recent_invoice.due_at,
				totalAmount: data.me.recent_invoice.total_amount,
				referenceNr: data.me.recent_invoice.invoice_reference_nr,
			};
		},
	});
