/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UpdateUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  phone?: Types.InputMaybe<Types.Scalars['String']['input']>;
  address?: Types.InputMaybe<Types.Scalars['String']['input']>;
  postCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  city?: Types.InputMaybe<Types.Scalars['String']['input']>;
  politicalExposure?: Types.InputMaybe<Types.PoliticalExposure>;
  occupationCategory?: Types.InputMaybe<Types.OccupationCategory>;
  employmentDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  iban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  netIncomeMonthly?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  numberOfDependents?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  monthlyLivingExpenses?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  expenditureMonthly?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  planningNewDebts?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  futureReducedEarnings?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  ultimateBeneficialOwner?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  newsletterAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  overdueDebt?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type UpdateUserMutation = { update_user?: { id: number } | null };



export const UpdateUserDocument = `
    mutation UpdateUser($userId: Int!, $email: String, $phone: String, $address: String, $postCode: String, $city: String, $politicalExposure: PoliticalExposure, $occupationCategory: OccupationCategory, $employmentDate: String, $iban: String, $netIncomeMonthly: Float, $numberOfDependents: Int, $monthlyLivingExpenses: Float, $expenditureMonthly: Float, $planningNewDebts: Float, $futureReducedEarnings: Float, $ultimateBeneficialOwner: Boolean, $newsletterAgreement: Boolean, $overdueDebt: Float) {
  update_user(
    user_id: $userId
    email: $email
    phone: $phone
    newsletter_agreement: $newsletterAgreement
    address: $address
    post_code: $postCode
    city: $city
    occupation_category: $occupationCategory
    employment_date: $employmentDate
    iban: $iban
    net_income_monthly: $netIncomeMonthly
    number_of_dependents: $numberOfDependents
    monthly_living_expenses: $monthlyLivingExpenses
    expenditure_monthly: $expenditureMonthly
    political_exposure: $politicalExposure
    planning_new_debts: $planningNewDebts
    future_reduced_earnings: $futureReducedEarnings
    ultimate_beneficial_owner: $ultimateBeneficialOwner
    overdue_debt: $overdueDebt
  ) {
    id
  }
}
    `;

export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUser'],
    mutationFn: (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
    ...options
  }
    )};


useUpdateUserMutation.fetcher = (variables: UpdateUserMutationVariables, options?: RequestInit['headers']) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables, options);
