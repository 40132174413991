import { LOCIZE_ERROR_KEYS, LOCIZE_NAMESPACES } from '@config/locize';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export const useToast = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.errors);

	const showErrorMessage = useCallback(
		(message?: string) => {
			toast.error(t(LOCIZE_ERROR_KEYS.generalError), {
				description: message,
			});
		},
		[t],
	);

	const showSuccessMessage = useCallback(
		(message: string) => {
			toast.success(t(message));
		},
		[t],
	);

	return {
		showErrorMessage,
		showSuccessMessage,
	};
};
