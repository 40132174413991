/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UpdateUserLanguageMutationVariables = Types.Exact<{
  languageAbbr: Types.Scalars['String']['input'];
  userId: Types.Scalars['Int']['input'];
}>;


export type UpdateUserLanguageMutation = { update_user_language: boolean };



export const UpdateUserLanguageDocument = `
    mutation UpdateUserLanguage($languageAbbr: String!, $userId: Int!) {
  update_user_language(language_abbr: $languageAbbr, user_id: $userId)
}
    `;

export const useUpdateUserLanguageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserLanguageMutation, TError, UpdateUserLanguageMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserLanguageMutation, TError, UpdateUserLanguageMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUserLanguage'],
    mutationFn: (variables?: UpdateUserLanguageMutationVariables) => fetcher<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument, variables)(),
    ...options
  }
    )};


useUpdateUserLanguageMutation.fetcher = (variables: UpdateUserLanguageMutationVariables, options?: RequestInit['headers']) => fetcher<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument, variables, options);
