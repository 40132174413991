import { LOCIZE_ERROR_KEYS } from '@config/locize';
import * as z from 'zod';

export const PayseraBanklinkLoginFormSchema = z.object({
	pin: z.string().min(1, {
		message: LOCIZE_ERROR_KEYS.validationRequired,
	}),
	payment_method_key: z.string().min(1, {
		message: LOCIZE_ERROR_KEYS.validationRequired,
	}),
});

export type PayseraBanklinkLoginFormType = z.infer<
	typeof PayseraBanklinkLoginFormSchema
>;
