import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@components/ui/popover';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@components/ui/tooltip';
import { useIsMobileView } from '@hooks/system';
import type { FC, PropsWithChildren } from 'react';

type AppTooltipProps = PropsWithChildren<{
	text: string;
	asChild?: boolean;
	className?: string;
}>;

export const AppTooltip: FC<AppTooltipProps> = ({
	children,
	text,
	asChild,
	className,
}) => {
	const isMobileView = useIsMobileView();

	if (isMobileView) {
		return (
			<Popover>
				<PopoverTrigger asChild={asChild} type="button">
					{children}
				</PopoverTrigger>
				<PopoverContent className={className}>{text}</PopoverContent>
			</Popover>
		);
	}

	return (
		<TooltipProvider delayDuration={0}>
			<Tooltip>
				<TooltipTrigger asChild={asChild} type="button">
					{children}
				</TooltipTrigger>
				<TooltipContent className={className}>
					<p>{text}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};
