import { createEvent, createStore } from 'effector';

const toggleEv = createEvent();
const setIsOpenEv = createEvent<boolean>();

const $isOpen = createStore(false)
	.on(toggleEv, (state) => !state)
	.on(setIsOpenEv, (_, payload) => payload);

export const sidebarModel = {
	store: { $isOpen },
	events: { toggleEv, setIsOpenEv },
};
