import { AppLanguage } from '@/shared/types';

export const LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP: Readonly<
	Record<string, AppLanguage>
> = {
	'en-us': AppLanguage.EN,
	'et-ee': AppLanguage.ET,
	'lv-lv': AppLanguage.LV,
	'lt-lt': AppLanguage.LT,
	'ru-ru': AppLanguage.RU,
};

export const LANGUAGE_ABBREVIATION_BY_SHORTNAME: Readonly<
	Record<string, string>
> = {
	[AppLanguage.EN]: 'en-us',
	[AppLanguage.ET]: 'et-ee',
	[AppLanguage.LV]: 'lv-lv',
	[AppLanguage.LT]: 'lt-lt',
	[AppLanguage.RU]: 'ru-ru',
};
