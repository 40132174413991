import {
	ConsumerLoanProduct,
	type EstoProductType,
	NonLoanProduct,
} from '@/shared/types';
import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const invoicesSearchSchema = z.object({
	selectedLoanOffer: z
		.custom<EstoProductType>(
			(value) =>
				!value ||
				value in ConsumerLoanProduct ||
				value === NonLoanProduct.CREDIT_LINE,
		)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/_main/invoices')({
	validateSearch: invoicesSearchSchema,
});
