import { APP_CONFIG } from '@config/app';
import type { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const withHelmet = (component: () => ReactNode) => () => (
	<HelmetProvider>
		<Helmet
			defaultTitle={APP_CONFIG.name}
			titleTemplate={`%s | ${APP_CONFIG.name}`}
		/>
		{component()}
	</HelmetProvider>
);
