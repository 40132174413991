import { withProviders } from '@/app/providers';
import { AppRouter } from '@/app/router/AppRouter';
import { Toaster } from '@components/ui/sonner';
import '@config/envs';
import '@lib/i18Next';
import './sentry';
import './styles/index.css';

const App = () => (
	<>
		<AppRouter />
		<Toaster />
	</>
);

export default withProviders(App);
