import { GetkevinStatusGroupType } from '@/shared/types';
import { RouteName } from '@config/routes';
import { AppPaymentMethod, paymentsApi } from '@entities/payments';
import { CreditCardPaymentStatus } from '@features/credit-card-payment';
import { InvoicePaymentFormView } from '@pages/invoice-payment/config';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const invoiceSearchSchema = z.object({
	withPremium: z.boolean().optional().catch(false),
	paymentMethod: z
		.nativeEnum(AppPaymentMethod)
		.catch(AppPaymentMethod.BANKLINK),
	formView: z
		.nativeEnum(InvoicePaymentFormView)
		.optional()
		.catch(InvoicePaymentFormView.OVERVIEW),

	// banklink
	paymentId: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),

	// credit card
	payment_status: z
		.nativeEnum(CreditCardPaymentStatus)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/payment/invoice/')({
	validateSearch: invoiceSearchSchema,
	beforeLoad: async ({
		search: { paymentId, hash, payment_status },
		context: { queryClient },
	}) => {
		if (paymentId && hash) {
			const data = await queryClient.fetchQuery({
				queryKey: paymentsApi.useGetkevinBanklinkPaymentQuery.getKey({
					getkevinRequestId: paymentId,
					hash,
				}),
				queryFn: paymentsApi.useGetkevinBanklinkPaymentQuery.fetcher({
					getkevinRequestId: paymentId,
					hash,
				}),
			});

			if (data?.payment?.status_group === GetkevinStatusGroupType.COMPLETED) {
				throw redirect({
					to: RouteName.PAYMENT_INVOICE_SUCCESS,
					replace: true,
				});
			}

			if (data?.payment?.status_group === GetkevinStatusGroupType.FAILED) {
				throw redirect({
					to: RouteName.PAYMENT_INVOICE_REJECT,
					replace: true,
				});
			}

			return;
		}

		if (payment_status === CreditCardPaymentStatus.SUCCESS) {
			throw redirect({
				to: RouteName.PAYMENT_INVOICE_SUCCESS,
				replace: true,
			});
		}

		if (payment_status === CreditCardPaymentStatus.FAILED) {
			throw redirect({
				to: RouteName.PAYMENT_INVOICE_REJECT,
				replace: true,
			});
		}
	},
});
