import CancelIcon from '@/shared/assets/icons/close.svg?react';
import { Typography } from '@components/typography';
import { Button } from '@components/ui/button';
import { APP_CONFIG } from '@config/app';
import { LOCIZE_ERROR_SCREEN_KEYS, LOCIZE_NAMESPACES } from '@config/locize';
import { useIsUserAuthorized } from '@entities/user';
import { useAppConfig } from '@hooks/system';
import { Link } from '@tanstack/react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.errorScreen);

	const { data: isAuthorized } = useIsUserAuthorized();
	const { supportUrl } = useAppConfig();

	return (
		<>
			<Helmet title="404" />
			<div className="flex size-full flex-col justify-center px-8 pt-28 pb-20 text-center md:justify-start">
				<div className="mx-auto flex w-full max-w-[25rem] flex-col items-center">
					<CancelIcon className="mb-9" />
					<Typography variant="m" className="mb-8">
						{t(LOCIZE_ERROR_SCREEN_KEYS.title)}
					</Typography>
					<Typography variant="text-l" className="mb-14">
						{t(LOCIZE_ERROR_SCREEN_KEYS.notFoundDescription)}
					</Typography>
					<div className="grid w-full gap-4">
						<Button fullWidth asChild>
							{isAuthorized ? (
								<Link to="/dashboard" replace>
									{t(LOCIZE_ERROR_SCREEN_KEYS.goHomeButton)}
								</Link>
							) : (
								<Link
									to="/auth"
									replace
									search={{
										authMethod: APP_CONFIG.authMethods[0],
									}}
								>
									{t(LOCIZE_ERROR_SCREEN_KEYS.goHomeButton)}
								</Link>
							)}
						</Button>
						<Button fullWidth asChild variant="grey">
							<a href={supportUrl}>
								{t(LOCIZE_ERROR_SCREEN_KEYS.supportButtonText)}
							</a>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
