/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, useMutation, UseQueryOptions, UseSuspenseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type PayseraMethodFragment = { key: string, title: string, logo_url: string };

export type PayseraPaymentMethodsQueryVariables = Types.Exact<{
  language_abbr: Types.Scalars['String']['input'];
}>;


export type PayseraPaymentMethodsQuery = { paysera_payment_methods?: Array<{ key: string, title: string, logo_url: string } | null> | null };

export type PayseraLoginChallengeMutationVariables = Types.Exact<{
  pin: Types.Scalars['String']['input'];
  payment_method_key: Types.Scalars['String']['input'];
  magic_login_url: Types.Scalars['String']['input'];
  accept_url: Types.Scalars['String']['input'];
  cancel_url: Types.Scalars['String']['input'];
  invite?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type PayseraLoginChallengeMutation = { paysera_login_challenge?: { session_id?: string | null, redirect_url?: string | null } | null };


export const PayseraMethodFragmentDoc = `
    fragment PayseraMethod on PayseraPaymentMethod {
  key
  title
  logo_url
}
    `;
export const PayseraPaymentMethodsDocument = `
    query PayseraPaymentMethods($language_abbr: String!) {
  paysera_payment_methods(language_abbr: $language_abbr) {
    ...PayseraMethod
  }
}
    ${PayseraMethodFragmentDoc}`;

export const usePayseraPaymentMethodsQuery = <
      TData = PayseraPaymentMethodsQuery,
      TError = unknown
    >(
      variables: PayseraPaymentMethodsQueryVariables,
      options?: Omit<UseQueryOptions<PayseraPaymentMethodsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<PayseraPaymentMethodsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<PayseraPaymentMethodsQuery, TError, TData>(
      {
    queryKey: ['PayseraPaymentMethods', variables],
    queryFn: fetcher<PayseraPaymentMethodsQuery, PayseraPaymentMethodsQueryVariables>(PayseraPaymentMethodsDocument, variables),
    ...options
  }
    )};

usePayseraPaymentMethodsQuery.getKey = (variables: PayseraPaymentMethodsQueryVariables) => ['PayseraPaymentMethods', variables];

export const useSuspensePayseraPaymentMethodsQuery = <
      TData = PayseraPaymentMethodsQuery,
      TError = unknown
    >(
      variables: PayseraPaymentMethodsQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<PayseraPaymentMethodsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<PayseraPaymentMethodsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<PayseraPaymentMethodsQuery, TError, TData>(
      {
    queryKey: ['PayseraPaymentMethodsSuspense', variables],
    queryFn: fetcher<PayseraPaymentMethodsQuery, PayseraPaymentMethodsQueryVariables>(PayseraPaymentMethodsDocument, variables),
    ...options
  }
    )};

useSuspensePayseraPaymentMethodsQuery.getKey = (variables: PayseraPaymentMethodsQueryVariables) => ['PayseraPaymentMethodsSuspense', variables];


usePayseraPaymentMethodsQuery.fetcher = (variables: PayseraPaymentMethodsQueryVariables, options?: RequestInit['headers']) => fetcher<PayseraPaymentMethodsQuery, PayseraPaymentMethodsQueryVariables>(PayseraPaymentMethodsDocument, variables, options);

export const PayseraLoginChallengeDocument = `
    mutation PayseraLoginChallenge($pin: String!, $payment_method_key: String!, $magic_login_url: String!, $accept_url: String!, $cancel_url: String!, $invite: String) {
  paysera_login_challenge(
    pin: $pin
    payment_method_key: $payment_method_key
    magic_login_url: $magic_login_url
    accept_url: $accept_url
    cancel_url: $cancel_url
    invite: $invite
  ) {
    session_id
    redirect_url
  }
}
    `;

export const usePayseraLoginChallengeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PayseraLoginChallengeMutation, TError, PayseraLoginChallengeMutationVariables, TContext>) => {
    
    return useMutation<PayseraLoginChallengeMutation, TError, PayseraLoginChallengeMutationVariables, TContext>(
      {
    mutationKey: ['PayseraLoginChallenge'],
    mutationFn: (variables?: PayseraLoginChallengeMutationVariables) => fetcher<PayseraLoginChallengeMutation, PayseraLoginChallengeMutationVariables>(PayseraLoginChallengeDocument, variables)(),
    ...options
  }
    )};


usePayseraLoginChallengeMutation.fetcher = (variables: PayseraLoginChallengeMutationVariables, options?: RequestInit['headers']) => fetcher<PayseraLoginChallengeMutation, PayseraLoginChallengeMutationVariables>(PayseraLoginChallengeDocument, variables, options);
