/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type EparakstsLoginQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  return_url: Types.Scalars['String']['input'];
}>;


export type EparakstsLoginQuery = { eparaksts_login?: { is_authenticated?: boolean | null } | null };



export const EparakstsLoginDocument = `
    query EparakstsLogin($code: String!, $return_url: String!) {
  eparaksts_login(code: $code, return_url: $return_url) {
    is_authenticated
  }
}
    `;

export const useEparakstsLoginQuery = <
      TData = EparakstsLoginQuery,
      TError = unknown
    >(
      variables: EparakstsLoginQueryVariables,
      options?: Omit<UseQueryOptions<EparakstsLoginQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<EparakstsLoginQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<EparakstsLoginQuery, TError, TData>(
      {
    queryKey: ['EparakstsLogin', variables],
    queryFn: fetcher<EparakstsLoginQuery, EparakstsLoginQueryVariables>(EparakstsLoginDocument, variables),
    ...options
  }
    )};

useEparakstsLoginQuery.getKey = (variables: EparakstsLoginQueryVariables) => ['EparakstsLogin', variables];

export const useSuspenseEparakstsLoginQuery = <
      TData = EparakstsLoginQuery,
      TError = unknown
    >(
      variables: EparakstsLoginQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<EparakstsLoginQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<EparakstsLoginQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<EparakstsLoginQuery, TError, TData>(
      {
    queryKey: ['EparakstsLoginSuspense', variables],
    queryFn: fetcher<EparakstsLoginQuery, EparakstsLoginQueryVariables>(EparakstsLoginDocument, variables),
    ...options
  }
    )};

useSuspenseEparakstsLoginQuery.getKey = (variables: EparakstsLoginQueryVariables) => ['EparakstsLoginSuspense', variables];


useEparakstsLoginQuery.fetcher = (variables: EparakstsLoginQueryVariables, options?: RequestInit['headers']) => fetcher<EparakstsLoginQuery, EparakstsLoginQueryVariables>(EparakstsLoginDocument, variables, options);
