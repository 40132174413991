/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UserCreditAccountWithdrawalSuccessInfoQueryVariables = Types.Exact<{
  hash: Types.Scalars['String']['input'];
}>;


export type UserCreditAccountWithdrawalSuccessInfoQuery = { current_credit_account_withdrawal?: { id: number, type: Types.CreditAccountWithdrawalType, eligibility_state: number, simple_eligibility_status: string } | null };



export const UserCreditAccountWithdrawalSuccessInfoDocument = `
    query UserCreditAccountWithdrawalSuccessInfo($hash: String!) {
  current_credit_account_withdrawal(hash: $hash) {
    id
    type
    eligibility_state
    simple_eligibility_status
  }
}
    `;

export const useUserCreditAccountWithdrawalSuccessInfoQuery = <
      TData = UserCreditAccountWithdrawalSuccessInfoQuery,
      TError = unknown
    >(
      variables: UserCreditAccountWithdrawalSuccessInfoQueryVariables,
      options?: Omit<UseQueryOptions<UserCreditAccountWithdrawalSuccessInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserCreditAccountWithdrawalSuccessInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserCreditAccountWithdrawalSuccessInfoQuery, TError, TData>(
      {
    queryKey: ['UserCreditAccountWithdrawalSuccessInfo', variables],
    queryFn: fetcher<UserCreditAccountWithdrawalSuccessInfoQuery, UserCreditAccountWithdrawalSuccessInfoQueryVariables>(UserCreditAccountWithdrawalSuccessInfoDocument, variables),
    ...options
  }
    )};

useUserCreditAccountWithdrawalSuccessInfoQuery.getKey = (variables: UserCreditAccountWithdrawalSuccessInfoQueryVariables) => ['UserCreditAccountWithdrawalSuccessInfo', variables];

export const useSuspenseUserCreditAccountWithdrawalSuccessInfoQuery = <
      TData = UserCreditAccountWithdrawalSuccessInfoQuery,
      TError = unknown
    >(
      variables: UserCreditAccountWithdrawalSuccessInfoQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserCreditAccountWithdrawalSuccessInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserCreditAccountWithdrawalSuccessInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserCreditAccountWithdrawalSuccessInfoQuery, TError, TData>(
      {
    queryKey: ['UserCreditAccountWithdrawalSuccessInfoSuspense', variables],
    queryFn: fetcher<UserCreditAccountWithdrawalSuccessInfoQuery, UserCreditAccountWithdrawalSuccessInfoQueryVariables>(UserCreditAccountWithdrawalSuccessInfoDocument, variables),
    ...options
  }
    )};

useSuspenseUserCreditAccountWithdrawalSuccessInfoQuery.getKey = (variables: UserCreditAccountWithdrawalSuccessInfoQueryVariables) => ['UserCreditAccountWithdrawalSuccessInfoSuspense', variables];


useUserCreditAccountWithdrawalSuccessInfoQuery.fetcher = (variables: UserCreditAccountWithdrawalSuccessInfoQueryVariables, options?: RequestInit['headers']) => fetcher<UserCreditAccountWithdrawalSuccessInfoQuery, UserCreditAccountWithdrawalSuccessInfoQueryVariables>(UserCreditAccountWithdrawalSuccessInfoDocument, variables, options);
