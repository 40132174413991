import { useUserRecentInvoice } from '@entities/invoices';
import { useWithdrawalAmountPricingConfig } from '@entities/pricing';
import { useUserCreditAccount } from '@entities/user';
import { useMemo } from 'react';
import { CreditLineBalanceState } from './types';

export const useCreditLineBalanceState = () => {
	const { data: creditAccount } = useUserCreditAccount();
	const { data: userInvoiceState } = useUserRecentInvoice();
	const { minWithdrawalAmount } = useWithdrawalAmountPricingConfig();

	return useMemo((): Nullable<CreditLineBalanceState> => {
		if (!creditAccount?.isActive) {
			return CreditLineBalanceState.NO_SIGNED;
		}

		if (userInvoiceState?.isOverdue) {
			return CreditLineBalanceState.LATE_INVOICE;
		}

		if (
			creditAccount.unpaidPrincipal === creditAccount.creditLimit ||
			creditAccount.creditLimit - creditAccount.unpaidPrincipal <
				minWithdrawalAmount
		) {
			return CreditLineBalanceState.NO_CREDIT_LEFT;
		}

		return CreditLineBalanceState.SIGNED;
	}, [creditAccount, userInvoiceState?.isOverdue, minWithdrawalAmount]);
};
