import { queryClient } from '@lib/queryClient';
import type {
	QueryKey,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';

type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
interface QueryWithKey<TData = any, TVariables = any> {
	(
		variables?: TVariables,
		options?: Omit<UseQueryOptions<TData, unknown, TData>, 'queryKey'> & {
			queryKey?: UseQueryOptions<TData, unknown, TData>['queryKey'];
		},
	): UseQueryResult<TData, unknown>;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	getKey: (variables?: any) => QueryKey;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	fetcher: (variables?: any, options?: any) => () => Promise<TData>;
}

type QueryParams<TQuery extends QueryWithKey> = Parameters<TQuery>[0];

const hasVariablesTypeGuard = <TQuery extends QueryWithKey>(
	variables?: ServerPreFetchOptions<TQuery>,
	// @ts-expect-error 1
): variables is {
	variables: QueryParams<TQuery>[0];
} => !!Object.keys(variables || {}).length;

type ServerPreFetchOptions<TQuery extends QueryWithKey> =
	Parameters<TQuery>[0] extends
		| Exact<{
				[key: string]: never;
		  }>
		| undefined
		? undefined
		: {
				variables?: Parameters<TQuery>[0];
			};

type FetcherReturnValue<TQuery extends QueryWithKey> = Awaited<
	ReturnType<ReturnType<TQuery['fetcher']>>
>;

export const serverFetch = async <TQuery extends QueryWithKey>(
	useQuery: TQuery,
	queryOptions?: ServerPreFetchOptions<TQuery>,
) => {
	const hasVariables = hasVariablesTypeGuard<TQuery>(queryOptions);
	let variables: QueryParams<TQuery> | undefined;
	if (hasVariables) {
		variables = queryOptions.variables;
	}
	const data = await queryClient.fetchQuery<
		FetcherReturnValue<TQuery>,
		QueryParams<TQuery>
	>({
		queryKey: useQuery.getKey(variables),
		queryFn: useQuery.fetcher(variables),
	});
	return data;
};
