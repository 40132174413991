/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type StartEverypayPaymentMutationVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  amount: Types.Scalars['Float']['input'];
  requestCcToken?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  transactionType: Types.EverypayPaymentTransactionType;
  ccToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
  redirectUrl: Types.Scalars['String']['input'];
  payment_category?: Types.InputMaybe<Types.PaymentCategoryType>;
}>;


export type StartEverypayPaymentMutation = { payment?: { payment_link?: string | null } | null };



export const StartEverypayPaymentDocument = `
    mutation StartEverypayPayment($userId: Int, $amount: Float!, $requestCcToken: Boolean, $transactionType: EverypayPaymentTransactionType!, $ccToken: String, $redirectUrl: String!, $payment_category: PaymentCategoryType) {
  payment: start_everypay_payment(
    user_id: $userId
    amount: $amount
    request_cc_token: $requestCcToken
    transaction_type: $transactionType
    cc_token: $ccToken
    redirect_url: $redirectUrl
    payment_category: $payment_category
  ) {
    payment_link
  }
}
    `;

export const useStartEverypayPaymentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<StartEverypayPaymentMutation, TError, StartEverypayPaymentMutationVariables, TContext>) => {
    
    return useMutation<StartEverypayPaymentMutation, TError, StartEverypayPaymentMutationVariables, TContext>(
      {
    mutationKey: ['StartEverypayPayment'],
    mutationFn: (variables?: StartEverypayPaymentMutationVariables) => fetcher<StartEverypayPaymentMutation, StartEverypayPaymentMutationVariables>(StartEverypayPaymentDocument, variables)(),
    ...options
  }
    )};


useStartEverypayPaymentMutation.fetcher = (variables: StartEverypayPaymentMutationVariables, options?: RequestInit['headers']) => fetcher<StartEverypayPaymentMutation, StartEverypayPaymentMutationVariables>(StartEverypayPaymentDocument, variables, options);
