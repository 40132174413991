/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type ActiveLanguageFragment = { abbreviation: string };

export type ActiveLanguagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveLanguagesQuery = { active_languages?: Array<{ abbreviation: string } | null> | null };


export const ActiveLanguageFragmentDoc = `
    fragment ActiveLanguage on Language {
  abbreviation
}
    `;
export const ActiveLanguagesDocument = `
    query ActiveLanguages {
  active_languages {
    ...ActiveLanguage
  }
}
    ${ActiveLanguageFragmentDoc}`;

export const useActiveLanguagesQuery = <
      TData = ActiveLanguagesQuery,
      TError = unknown
    >(
      variables?: ActiveLanguagesQueryVariables,
      options?: Omit<UseQueryOptions<ActiveLanguagesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ActiveLanguagesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ActiveLanguagesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ActiveLanguages'] : ['ActiveLanguages', variables],
    queryFn: fetcher<ActiveLanguagesQuery, ActiveLanguagesQueryVariables>(ActiveLanguagesDocument, variables),
    ...options
  }
    )};

useActiveLanguagesQuery.getKey = (variables?: ActiveLanguagesQueryVariables) => variables === undefined ? ['ActiveLanguages'] : ['ActiveLanguages', variables];

export const useSuspenseActiveLanguagesQuery = <
      TData = ActiveLanguagesQuery,
      TError = unknown
    >(
      variables?: ActiveLanguagesQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<ActiveLanguagesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<ActiveLanguagesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<ActiveLanguagesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ActiveLanguagesSuspense'] : ['ActiveLanguagesSuspense', variables],
    queryFn: fetcher<ActiveLanguagesQuery, ActiveLanguagesQueryVariables>(ActiveLanguagesDocument, variables),
    ...options
  }
    )};

useSuspenseActiveLanguagesQuery.getKey = (variables?: ActiveLanguagesQueryVariables) => variables === undefined ? ['ActiveLanguagesSuspense'] : ['ActiveLanguagesSuspense', variables];


useActiveLanguagesQuery.fetcher = (variables?: ActiveLanguagesQueryVariables, options?: RequestInit['headers']) => fetcher<ActiveLanguagesQuery, ActiveLanguagesQueryVariables>(ActiveLanguagesDocument, variables, options);
