import { Skeleton } from '@components/ui/skeleton';
import type { FC } from 'react';

import styles from './CreditLineBlockSkeleton.module.css';

export const CreditLineBlockSkeleton: FC = () => (
	<div className={styles.skeleton}>
		{Array.from({ length: 2 }).map((_, index) => (
			<div key={index.toString()} className={styles.skeletonBlock}>
				<Skeleton className="h-7" />
				<Skeleton className="h-20" />
			</div>
		))}
	</div>
);
