import { AppTooltip } from '@components/app-tooltip';
import InfoIcon from '@icons/info.svg?react';
import { cn } from '@utils/tailwind';
import type { FC } from 'react';

type InfoTooltipProps = {
	text: string;
	className?: string;
	iconClassName?: string;
};

export const InfoTooltip: FC<InfoTooltipProps> = ({
	text,
	className,
	iconClassName,
}) => (
	<AppTooltip className={className} text={text}>
		<InfoIcon className={cn('text-neutral-300', iconClassName)} />
	</AppTooltip>
);
