import type { AppLanguage } from '@/shared/types';

export enum DealId {
	PHEROMONE = 'pheromone',
	IRRIGATOR = 'irrigator',
	SINISE_VALGUSE = 'siniseValguse',
	SPORTLIK = 'sportlik',
	CASEPRO = 'casepro',
	GOFIT = 'goFit',
	MOKYKLINE = 'mokykline',
	WOLT_EE = 'wolt-ee',
	WOLT_LV = 'wolt-lv',
	WOLT_LT = 'wolt-lt',
	UPGREAT_EE = 'upgreat-ee',
	UPGREAT_LV = 'upgreat-lv',
	UPGREAT_LT = 'upgreat-lt',
	FITLAP = 'fitlap',
	KULDAN = 'kuldan',
}

export type Deal = {
	id: DealId;
	brand: string;
	title: string;
	description: string;
	discountCode: string;
	discount: string;
	imageSrc: string;
	websiteUrl: string | Record<AppLanguage, string>;
};
