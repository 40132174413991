export const LOCIZE_DEALS_KEYS = {
	kuldan: 'kuldan',
	pheromone: 'pheromone',
	irrigaator: 'irrigaator',
	siniseValguse: 'sinise-valguse',
	sportlik: 'sportlik',
	casepro: 'casepro',
	upgreat: 'upgreat',
	fitlap: 'fitlap',
	goFit: 'go-fit',
	mokykline: 'mokykline',
	manguarvutid: 'manguarvutid',
	wolt: 'wolt',
	dialogDescription: 'dialog.description',
	dialogDescription2: 'dialog.description-2',
	dialogCopyDisclaimer: 'dialog.copy-disclaimer',
	dialogCopyButton: 'dialog.copy-button.default',
	dialogCopyButtonSuccess: 'dialog.copy-button.success',
	dialogRedirectButton: 'dialog.redirect-button',
} as const;
