import { CreditLineTabs } from '@pages/credit-line';
import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const CreditLineSearchSchema = z.object({
	tab: z.nativeEnum(CreditLineTabs).optional().catch(undefined),
});

export const Route = createFileRoute('/_protected/_main/credit-line')({
	validateSearch: CreditLineSearchSchema,
});
