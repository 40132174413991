import { createEvent, createStore } from 'effector';

const triggerViewEvent = createEvent('trigger view event');

export const $isViewEventTriggered = createStore(false).on(
	triggerViewEvent,
	() => true,
);

export const dealsListModel = {
	store: { $isViewEventTriggered },
	events: { triggerViewEvent },
};
